import React from 'react'
import { SVG } from '../../../Constant/SvgConstant'

const EditSign = () => {
    return (
        <div className='edit-icon scale-in-center'>
            <img src={SVG.EDIT_ICON} alt="" />
        </div>
    )
}

export default EditSign
