import React from "react";
import { SVG } from "../../Constant/SvgConstant";

import styles from "./PostContainer.module.scss"
import { PremiumSign } from "./index";

const PostContainer = ({ image = '', thumbnail = "", is_paid = false, is_purchased = false, is_mine = false }) => {
    return (
        <div className={styles.imageWrapper}>
            {thumbnail !== "" ? (
                <div className="w-100 h-100 thumbnail-container">
                    {
                        is_paid ?
                            <PremiumSign />
                            : ''
                    }
                    <img src={thumbnail} alt="" className={`${styles.image}`} />
                    <span >
                        <img src={SVG.PLAY_ICON} alt="Play Sign" className="play-icon" />
                    </span>
                </div>

            ) : (
                <>
                    {
                        is_paid ?
                            <PremiumSign className="zIndex-1" />
                            : ''
                    }
                    <img src={image} alt="" className={`${styles.image} ${(is_paid && !is_purchased && !is_mine) ? 'blur' : ""}`} />
                </>
            )
            }
        </div >
    );
};

export default PostContainer;
