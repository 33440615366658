import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { EmptyContainer } from "../../../Components/CustomComponents";
import { useGetOtherProfileWorkoutQuery } from "../../../Data/services/otherUserApi";
import { ROUTES } from "../Route";
import styles from "../styles/index.module.css";
import { STRING } from "../../../Constant/StringConstant";


function MyWorkout({ user_slug, setPage }) {

  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);


  const { data, isFetching } = useGetOtherProfileWorkoutQuery({ slug: user_slug, page: currentPage, limit: 10, offset }, {
    refetchOnMountOrArgChange: true,
  });

  const listingData = useSelector(state => state.otherUser.workout.data)


  // MARK: USE EFFECT
  useEffect(() => {
    if (isFetching) return;
    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))
    }
  }, [isFetching])

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
    }
  }, [inView])


  const handlePageView = (details) => {
    setPage({
      route: !!details.is_paid ? ROUTES.PREMIUM_WORKOUT : ROUTES.WORKOUT,
      prev_route: STRING.Workout,
      data: details
    });
  };

  return (
    <>
      {(_.isEmpty(listingData) && !isFetching) ? <EmptyContainer text="No workout found" /> :
        <Row className="gy-3">
          {
            (listingData ?? []).map((item, index) => (
              <Col
                xs={12}
                md={6}
                key={item.slug}
                ref={
                  (listingData.length >= (total - offset)) ? null :
                    (index === (listingData.length - 1)) ? ref : null}
              >
                <div
                  className="workoutContainer"
                  role="button"
                  onClick={() => handlePageView(item)}
                >
                  <div className="imageWrapper">
                    <img src={item.image_url} alt="" className={`image `} />
                    {/* ${premium ? "premiumImage" : ""} */}
                    <div className="gradientOverlay"></div>
                  </div>

                  {item.is_paid ? <PremiumTxt /> : ""}

                  {/* <FreeTag text={"Free"} /> */}

                  <p className="title lc-1 m-0 fs-5 mb-5"> {item.title} </p>
                  <div className={styles.workout_styles}>
                    {(item.tags ?? []).map((tag, index) => (
                      <div key={index} className={`scale-in-center ${styles.tag_btn}`}>
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            ))}
        </Row>

      }
      {isFetching ? <div className="w-100 d-flex justify-content-center py-4 my-2 text-light"><Spinner color="white" /></div> : ""}
    </>
  );
}

const PremiumTxt = () => {
  return <div className={`scale-in-center ${styles.premium}`}>Premium</div>;
};

export default MyWorkout;
