import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { EmptyContainer, Loader } from "../../../Components/CustomComponents";
import UserAvatarWithNameAndTag from "../../../Components/CustomComponents/UserAvatarWithNameAndTag";
import { IMAGES } from "../../../Constant/ImageConstant";
import { STRING } from "../../../Constant/StringConstant";
import { useGetPostCommentQuery } from "../../../Data/services/userApi";
import { getTime } from "../../../Helper";



export default function PostComment({ post_slug }) {

    const { isFetching } = useGetPostCommentQuery({ slug: post_slug })
    const data = useSelector(state => (state.user.my_shared_post.comment[post_slug] || []))

    return (
        <div>
            <p className=' fw-bold'> Comments </p>
            {
                isFetching ? <Loader /> :
                    _.isEmpty(data) ? <EmptyContainer text="No comments found" classes="pt-3 pb-4 m-0" /> :
                        <div className="Comment-Container">
                            {
                                ((data ?? []))?.map((comment, index) => (
                                    <div
                                        key={data.slug + '_' + index}
                                    >
                                        {index > 0 ? <hr style={{ width: '100%' }}></hr> : ""}
                                        <div className="d-flex flex-row justify-content-between w-100 my-3">
                                            <UserAvatarWithNameAndTag
                                                userName={comment.user.name ?? STRING.CommunityPostUserName}
                                                userTag={STRING.CommunityPostUserTag}
                                                avatarImage={comment.user.image_url ?? IMAGES.PROFILE_IMAGE}
                                            />
                                            <p className='m-0 post-date text-granite'>{getTime(comment.created_at)}</p>
                                        </div>
                                        <p className='text-granite fs-7'>
                                            {comment.comment}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
            }
        </div>
    )
}