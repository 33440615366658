import React from 'react'
import { SVG } from '../../../Constant/SvgConstant'


const PlaySign = () => {
    return (
        <div>
            <img src={SVG.PLAY_ICON} alt="" className='playBtn ' />
        </div>
    )
}

export default PlaySign
