import React, { useEffect, useState } from "react";

import GridCard from "./GridCard";

import { Col, Spinner } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useGetAllQuery } from "../../../Data/services/dashboardApi";


export default function AllListing({
    selectedValue = '',
    setPage = () => { },
    handleShowFreeTemplateModalOpen = () => { },
    handleShowPremiumTemplateModalOpen = () => { }
}) {
    const LIMIT = 18;
    const search = useSelector(state => state.search.text);

    const is_paid = !selectedValue ? "" : (selectedValue === 'paid');
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(false)
    const [isFetchedFirstTime, setIsFetchedFirstTime] = useState(false)

    const { data, isFetching, } = useGetAllQuery({ is_paid, page: currentPage, search, limit: LIMIT, offset }, {
        skip: skip,
        refetchOnMountOrArgChange: false,
    });

    const listingData = useSelector(state => state.dashboard.all[!selectedValue ? 'all' : selectedValue].data)

    // MARK: USE EFFECT
    useEffect(() => {
        if (isFetching) return;
        setIsFetchedFirstTime(true);
        setSkip(true)

        if (!data?.links?.total_records) return;

        if (currentPage === 1) {
            setTotal(data.links?.total_records)
        }
        else if (data.links?.total_records > total) {
            setTotal(data.links.total_records)
            setOffset(prev => prev + (data.links?.total_records - total))
        }
    }, [isFetching])

    useEffect(() => {
        if (inView && !isFetching) {
            setCurrentPage(prev => prev + 1);
            setSkip(false)
        }
    }, [inView])

    useEffect(() => {
        if (!isFetchedFirstTime) return;
        const timeout = setTimeout(() => {
            setCurrentPage(1)
            setOffset(0)
            setTotal(0)
            setSkip(false)
        }, 400)

        return () => clearTimeout(timeout)
    }, [search])

    return (
        <>
            {
                (listingData ?? []).map((item, index) => (
                    <Col
                        lg={4} md={4} sm={6}
                        className="mt-2 mb-2 focus-in-expand-slow"
                        key={item.slug}
                        ref={
                            (listingData.length >= (total - offset)) ? null :
                                (index === (listingData.length - 1)) ? ref : null}

                    >
                        <GridCard
                            data={item}
                            setPage={setPage}
                            openFreeTemplate={handleShowFreeTemplateModalOpen}
                            openPremiumTemplate={handleShowPremiumTemplateModalOpen}
                        />

                    </Col>

                ))
            }
            {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}

        </>
    )
}