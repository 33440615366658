import { createSlice } from "@reduxjs/toolkit";
import { lookupApi } from "../services/lookupApi";
import { receivedSportsTypeDTOs } from "../DTO/registerDTO";

const initialState = {
  sportsType: null,
};

export const lookupSlice = createSlice({
  name: "lookups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        lookupApi.endpoints.getSportsLookup.matchFulfilled,
        (state, { payload }) => {
          state.sportsType = payload.data.map(item => ({ label: item.title, value: item.title }));
        }
      )
      .addMatcher(lookupApi.endpoints.getSportsLookup.matchRejected, (state) => {
        state.sportsType = null;
      });
  },
});

export const { } = lookupSlice.actions;

export default lookupSlice.reducer;
