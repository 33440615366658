import React from "react";

const CommunitySvgIcon = ({ color = "white" }) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2273 3.68705L15 7.04852L10 1.84061L5 7.04852L1.77273 3.68705V12.6589C1.77376 13.3625 2.0432 14.0232 2.50062 14.4996C2.95561 14.9735 3.55669 15.2262 4.16775 15.2273C4.16813 15.2273 4.1685 15.2273 4.16888 15.2273H15.8311C15.8315 15.2273 15.8319 15.2273 15.8322 15.2273C16.4433 15.2262 17.0444 14.9735 17.4994 14.4996C17.9567 14.0233 18.2262 13.3627 18.2273 12.6593C18.2273 12.6589 18.2273 12.6584 18.2273 12.658L18.2273 3.68705ZM20 12.6601C19.9987 13.8107 19.5593 14.9137 18.7782 15.7273C17.997 16.5409 16.938 16.9986 15.8333 17H4.16667C3.062 16.9986 2.00296 16.5409 1.22185 15.7273C0.440735 14.9137 0.00132321 13.8107 0 12.6601V3.47157C7.03739e-05 3.12828 0.0978568 2.79272 0.280997 2.50731C0.464138 2.2219 0.724409 1.99946 1.02891 1.86809C1.33341 1.73673 1.66846 1.70236 1.99172 1.76931C2.31497 1.83626 2.61191 2.00154 2.845 2.24424L5 4.48885L8.82167 0.508269C9.13421 0.182825 9.55806 0 10 0C10.4419 0 10.8658 0.182825 11.1783 0.508269L15 4.48885L17.155 2.24424C17.3877 2.00072 17.6847 1.83486 18.0083 1.76777C18.3318 1.70069 18.6672 1.73541 18.9717 1.86753C19.2761 1.99902 19.5364 2.2216 19.7194 2.50711C19.9024 2.79262 20.0001 3.12826 20 3.47157V12.6601Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunitySvgIcon;
