import React, { useEffect, useRef, useState } from 'react'
import { STRING } from '../../../Constant/StringConstant'
import CommunityPostWithComment from './CommunityPostWithComment'
import { useInView } from 'react-intersection-observer';
import { useGetForumQuery } from '../../../Data/services/CommunityApi';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

const CommunityPost = () => {

    const { ref, inView } = useInView({
        threshold: 0,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);

    const { data, isFetching } = useGetForumQuery({ page: currentPage, limit: 18, offset }, {
        refetchOnMountOrArgChange: true,
    });

    const listingData = useSelector(state => state.community.forum.data)

    // MARK: USE EFFECT
    useEffect(() => {
        if (isFetching) return;
        if (!data?.links?.total_records) return;

        if (currentPage === 1) {
            setTotal(data.links?.total_records)
        }
        else if (data.links?.total_records > total) {
            setTotal(data.links.total_records)
            setOffset(prev => prev + (data.links?.total_records - total))
        }
    }, [isFetching])

    useEffect(() => {
        if (inView && !isFetching) {
            setCurrentPage(prev => prev + 1);
        }
    }, [inView])


    return (
        <div className='pe-md-2'>
            {
                (listingData ?? []).map((post, index) => (

                    <div
                        index={post.slug}
                        ref={
                            (listingData.length >= (total - offset)) ? null :
                                (index === (listingData.length - 1)) ? ref : null}
                    >
                        <CommunityPostWithComment key={post.slug} communityPost={post} />
                    </div>
                ))
            }
            {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}
        </div >

    )
}

export default CommunityPost
