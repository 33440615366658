import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL, INITIAL_TOKEN } from "../../config/config";
import { getToken } from "./localStorageService";

// let token = `Bearer ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnpiSFZuSWpvaU1EQTFaR1JsTXprdFltSm1OeTAwWW1SbExXSm1Zakl0TUdGa09ETmpZems0TVRGaklpd2lhV0YwSWpveE56SXhPVGsyTmpZd0xDSmxlSEFpT2pFM01qSTJNREUwTmpBc0ltbHpjeUk2SWpZNFltUmlaREZqTFdFeE9UY3ROR1EzT0MwNU5EZ3pMVE5tT0dZeU9USmhOREppWlNJc0luTjFZaUk2SWpZNFltUmlaREZqTFdFeE9UY3ROR1EzT0MwNU5EZ3pMVE5tT0dZeU9USmhOREppWlNJc0ltcDBhU0k2SWpBd05XUmtaVE01TFdKaVpqY3ROR0prWlMxaVptSXlMVEJoWkRnelkyTTVPREV4WXlKOS5vUy10SHR5d3FjSVdTcHh0cUhHQmlQN3ZtN2JlblpWdncta3k0SDI1RUpn`;

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("token", INITIAL_TOKEN);
      let auth_token = getToken();
      if (auth_token) {
        headers.set("authorization", auth_token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
