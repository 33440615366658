import React, { useState } from 'react'
import { SubTitle } from '../../../Components/CustomComponents'
import { STRING } from '../../../Constant/StringConstant'
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import SelectFieldComponent from '../../../Components/CustomComponents/SelectFieldComponent';
import RoundedCheckBox from '../../../Components/CommonComponents/RoundedCheckBox';

const AdditionalInfoForm = () => {

    const optionList = ["English", "Chinese"];
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        // defaultValues:{
        //     email: '',
        //     password : '',
        // }
    });

    const handleChangePassword = async (data) => {
        console.log(data)
    }

    return (
        <div>

            <SubTitle text={STRING.AdditionalInformation} />

            <br></br>

            <Row>
                <Col sm={12} md={6}>
                    <SelectFieldComponent
                        // firstOption={v}
                        optionsList={optionList}
                        //   onSelectChange={handleIntensityChange}
                        // disabled={isEditProfile ? false : true}

                        firstOption={
                            selectedLanguage !== ""
                                ? selectedLanguage
                                : STRING.SelectSports
                        }
                        onSelectChange={(value) => setSelectedLanguage(value)}
                        // onSelectChange={onChange}

                        className={"select bg-darkJungleGreen"}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <div className='d-flex flex-row justify-content-between align-items-center mt-1 notification'>

                        <p className='m-0'> {STRING.PushNotification} </p>

                        <RoundedCheckBox />

                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default AdditionalInfoForm
