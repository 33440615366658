import React, { useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { INPUT_TYPES, INVALID_FLOAT_INPUT_TYPE } from "../../Constant/InputTypesAndPatternsConstant";
import { STRING } from "../../Constant/StringConstant";
import { Button, Checkbox, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
// import cssStyles from './styles/notification.module.css';
import { message, Upload } from "antd";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { IMAGES } from "../../Constant/ImageConstant";
import { DashboardPostPayload } from "../../Constant/PayloadConstant/DashboardPostPayloadConstant";
import { useCreatePostMutation } from "../../Data/services/dashboardApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import ErrorMessage from "../Errors/ErrorMessage";
import { POST_TYPE_ENUM } from "../../Constant/constants";

const { Dragger } = Upload;

const AddPostModal = ({ handleModalClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modalClass, setModalClass] = useState(
    "addPostModalContainer scale-in-hor-center"
  );

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedType, setSelectedType] = useState(POST_TYPE_ENUM.IMAGE);

  const [mediaImage, setMediaImage] = useState("");
  const [mediaVideo, setMediaVideo] = useState("");
  const [mediaThumbnail, setMediaThumbnail] = useState("");

  const [createPost, { isError, isLoading, isSuccess }] =
    useCreatePostMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  const handleCheckboxChange = (event) => {
    const value = event.target.value;

    if (selectedValue === value) {
      setSelectedValue(null);
    } else {
      setSelectedValue(value);
    }
  };

  const handleTypeCheckboxChange = (event) => {
    const value = event.target.value;

    if (selectedValue === value) {
      setSelectedType(null);
    } else {
      setSelectedType(value);
    }
  };

  const handleModalCloseWithAnimation = () => {
    setModalClass("addModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  // MARK: UPLOAD IMAGE

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast("You can only upload JPG/PNG file!", "error", false);
    } else if (!isLt2M) {
      Toast("Image must smaller than 2MB!", "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const beforeUploadVideo = (file) => {
    const isJpgOrPng = file.type === "video/mp4";
    const isLt2M = (file.size / 1024 / 1024) < 20;
    if (!isJpgOrPng) {
      Toast("You can only upload mp4 file!", "error", false);
    } else if (!isLt2M) {
      Toast("Image must smaller than 20MB!", "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const beforeUploadThumbnail = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast("You can only upload JPG/PNG file!", "error", false);
    } else if (!isLt2M) {
      Toast("Thumbnail must smaller than 2MB!", "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // upload files
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "image/jpeg,image/png",
    value: !_.isObject(mediaImage)
      ? mediaImage
      : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        setMediaImage(info.file.originFileObj);
      }
      if (status === "done") {
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage("");
    },
  };
  const videoProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "video/mp4",
    value: !_.isObject(mediaVideo)
      ? mediaVideo
      : URL.createObjectURL(mediaVideo),
    beforeUpload: beforeUploadVideo, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        setMediaVideo(info.file.originFileObj);
      }
      if (status === "done") {
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaVideo("");
    },
  };
  const thumbnailProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "image/jpeg,image/png",
    value: !_.isObject(mediaThumbnail)
      ? mediaThumbnail
      : URL.createObjectURL(mediaThumbnail),
    beforeUpload: beforeUploadThumbnail, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        setMediaThumbnail(info.file.originFileObj);
      }
      if (status === "done") {
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaThumbnail("");
    },
  };


  //MARK: Form Submit
  const handleFormSubmit = async (values) => {
    if (isSubmitted) return
    try {
      if (selectedType === POST_TYPE_ENUM.IMAGE) {
        if (!mediaImage) {
          setError("Image", { message: "Image is required" });
        }
      }
      else if (selectedType === POST_TYPE_ENUM.VIDEO) {
        if (!mediaVideo) {
          setError("Video", { message: "Video is required" });
        }
        if (!mediaVideo) {
          setError("Thumbnail", { message: "Thumbnail is required" });
        }
      }
      else {
        ErrorToast('Invalid post type')
      }

      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("caption", values.caption);
      if (selectedType === POST_TYPE_ENUM.IMAGE) {
        formData.append("type", POST_TYPE_ENUM.IMAGE);
        formData.append("image_url", mediaImage);
      }
      else {
        formData.append("type", POST_TYPE_ENUM.VIDEO);
        formData.append("video_url", mediaVideo);
        formData.append("thumbnail_url", mediaThumbnail);
      }

      formData.append("sports_type", "Cricket");
      if (selectedValue !== 'free') {
        formData.append("is_paid", '1');
        formData.append("price", parseFloat(values.price));
      }
      else {
        formData.append("is_paid", '0');
        formData.append("price", 0);
      }


      //   for (var pair of formData.entries()) {
      //     console.log(pair[0] + ", " + pair[1]);
      //   }

      const result = await createPost(formData).unwrap();
      if (result.code === 200) {
        message.success(result.message);
        handleModalCloseWithAnimation();
      }
      setIsSubmitted(false)

    } catch (e) {
      if (e?.data?.code === 400) {
        ErrorToast(e.data?.message)
      }
      setIsSubmitted(false)

    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <Form
            onSubmit={handleSubmit(handleFormSubmit)}
            className="w-100 scale-in-center"
          >
            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>

            <br></br>

            <div
              className="d-flex align-items-center w-100 justify-content-start"
              role="button"
              onClick={() => { }}
            >
              <div className="">
                <p className="fs-4 fw-semibold m-0">{STRING.NewPost}</p>
              </div>
            </div>
            {
              // MARK: DRAGGER
            }
            <div
              className={`d-flex flex-row justify-content-around checkBoxes w-100 py-3`}
            >
              <Checkbox
                classes=""
                label={STRING.IMAGE_TYPE}
                value={POST_TYPE_ENUM.IMAGE}
                checked={selectedType === POST_TYPE_ENUM.IMAGE}
                onChange={handleTypeCheckboxChange}
              />
              <Checkbox
                classes=""
                label={STRING.VIDEO_TYPE}
                value={POST_TYPE_ENUM.VIDEO}
                checked={selectedType === POST_TYPE_ENUM.VIDEO}
                onChange={handleTypeCheckboxChange}
              />
            </div>
            {(selectedType === POST_TYPE_ENUM.IMAGE) ?
              <div>
                <Dragger {...props} className="w-100">
                  {!_.isEmpty(mediaImage) ? (
                    <img
                      src={
                        !_.isObject(mediaImage)
                          ? mediaImage
                          : URL.createObjectURL(mediaImage)
                      }
                      alt="avatar"
                      style={{ width: "100%", height: "100px" }}
                      className="img-fluid"
                    />
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <img src={IMAGES.UPLOAD_ICON} alt="" />
                      </p>
                      <p className="ant-upload-text">
                        Drop your image here, or <span>browse</span>
                      </p>
                      <p className="ant-upload-hint">
                        {" "}
                        Supports: PNG, JPG, JPEG
                      </p>
                    </div>
                  )}
                </Dragger>
                {!mediaImage && <ErrorMessage record={errors["Image"]?.message} />}
              </div> : <div>
                <div className="mb-3 w-100">
                  <Dragger {...videoProps} className="w-100">
                    {!_.isEmpty(mediaVideo) ? (
                      <video
                        src={
                          !_.isObject(mediaVideo)
                            ? mediaVideo
                            : URL.createObjectURL(mediaVideo)
                        }
                        alt="avatar"
                        style={{ width: "100%", height: "100px" }}
                        className="img-fluid mb-4"
                      />
                    ) : (
                      <div>
                        <p className="ant-upload-drag-icon">
                          <img src={IMAGES.UPLOAD_ICON} alt="" />
                        </p>
                        <p className="ant-upload-text">
                          Drop your video here, or <span>browse</span>
                        </p>
                        <p className="ant-upload-hint">
                          {" "}
                          Supports: mp4{" "}
                        </p>
                      </div>
                    )}
                  </Dragger>
                  {!mediaVideo && <ErrorMessage record={errors["Video"]?.message} />}
                </div>
                <Dragger {...thumbnailProps} className="w-100">
                  {!_.isEmpty(mediaThumbnail) ? (
                    <img
                      src={
                        !_.isObject(mediaThumbnail)
                          ? mediaThumbnail
                          : URL.createObjectURL(mediaThumbnail)
                      }
                      alt="avatar"
                      style={{ width: "100%", height: "100px" }}
                      className="img-fluid"
                    />
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <img src={IMAGES.UPLOAD_ICON} alt="" />
                      </p>
                      <p className="ant-upload-text">
                        Drop video thumbnail here, or <span>browse</span>
                      </p>
                      <p className="ant-upload-hint">
                        {" "}
                        Supports: PNG, JPG, JPEG
                      </p>
                    </div>
                  )}
                </Dragger>
                {!mediaThumbnail && <ErrorMessage record={errors["Thumbnail"]?.message} />}
              </div>
            }
            {/* {!mediaImage && <ErrorMessage record={errors["Image"]?.message} />} */}

            <br></br>
            <Controller
              control={control}
              name={DashboardPostPayload.Caption}
              rules={{
                required: STRING.WriteCaption,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <>
                  <InputGroup>
                    <Input
                      placeholder={STRING.WriteCaption}
                      type={INPUT_TYPES.TEXT}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </InputGroup>
                  <ErrorMessage
                    record={errors[DashboardPostPayload.Caption]?.message}
                  />
                </>
              )}
            />

            <hr></hr>

            <div className="d-flex align-items-center w-100 justify-content-start">
              <div className="">
                <p className="fs-4 fw-semibold m-0">{STRING.AddPricing}</p>
                <p className="fs-7 m-0 description">
                  {STRING.PricingInstruction}
                </p>
              </div>
            </div>

            <div
              className={`d-flex flex-row justify-content-start checkBoxes w-100 py-3`}
            >
              <Checkbox
                classes=""
                label={STRING.SetFree}
                value="free"
                checked={selectedValue === "free"}
                onChange={handleCheckboxChange}
              />
            </div>

            {
              selectedValue !== "free" && (
                <div className="d-flex align-items-center w-100 justify-content-start">
                  <div className="">
                    <p className="fs-5 m-0 fs-6 fw-bold label">
                      {STRING.SetPricing}
                    </p>
                  </div>
                </div>
              )
            }

            {
              selectedValue !== "free" && (
                <Controller
                  control={control}
                  name={DashboardPostPayload.AddPricing}
                  rules={{
                    required: STRING.PriceMessage,
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <>
                      <InputGroup>
                        <Input
                          placeholder={STRING.PricePlaceholder}
                          type={INPUT_TYPES.NUMBER}
                          value={value}
                          onKeyDown={(e) => (INVALID_FLOAT_INPUT_TYPE.includes(e.key) && e.preventDefault())}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      </InputGroup>
                      <ErrorMessage
                        record={errors[DashboardPostPayload.AddPricing]?.message}
                      />
                    </>
                  )}
                />
              )
            }

            <br></br>

            <Button
              classes="scale-in-center-slow fs-5"
              text={
                <div className='d-flex align-items-center justify-content-center'>
                  {STRING.PostButton}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                </div>
              }
              type={INPUT_TYPES.SUBMIT}
            />
          </Form >
        </div >
      </ModalContainer >
    </div >
  );
};

export default AddPostModal;
