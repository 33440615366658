export const WEB_ROUTES = {
    ACCOUNT: 'account',
    DASHBOARD: 'dashboard',
    COMMUNITY: 'community',
    MENU: 'menu',
    // submenu
    PROGRESS: 'progress',
    ACCOLADES: 'accolades',
    FEEDBACK: 'feedback',
    CONTACTUS: 'contact-us',

    TEAM: 'team',
    SETTING: 'settings',
    PAYMENT: 'payment',
    PRIVACY: 'privacy-policy',

    // PAYMENT_METHOD: 'payment-methods',
    // CURRENCY: 'currency',
    // CUSTOMER_SUPPORT: 'customer-support',
    PROFILE: 'profile',
    OTHER_PROFILE: 'profile/',
    SUBSCRIPTION: 'subscription'
}

export const BREAKPOINTS = {
    MOBILE: 991
}


export const IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/*'

export const POST_TYPE_ENUM = {
    IMAGE: "IMAGE",
    VIDEO: "VIDEO",
    WORKOUT: "WORKOUT",
    TEMPLATE: "TEMPLATE",
}


export const LOG_TYPE_ENUM = {
    WEEK: "week",
    MONTH: "month",
    YEAR: "year",
}

export const MESSAGE_TYPE_ENUM = {
    TEXT: "TEXT",
}


export const GET_TRAINING_LOG_PAYLOAD_FORMAT = 'YYYY-MM-DD';