import React from "react";
import ReactPlayer from "react-player/lazy";
import PlaySign from "./PlaySign";

const HorizontalImageWithGradient = ({ image = '', video = "", thumbnail = "", is_paid = false, is_purchased = false }) => {
  return (
    <div className="imageWrapper mt-3">
      {video !== "" ? (
        (!is_paid || (is_paid && is_purchased)) ? <ReactPlayer
          url={video}
          width="100%"
          playing={true}
          controls={true}
        /> :
          <>
            <img src={thumbnail} alt="" className={`image`} />
            <PlaySign />
            <div className="gradientOverlay"></div>
          </>
      ) : (
        <>
          <img src={image} alt="" className={`image ${(is_paid && !is_purchased) ? 'blur' : ""}`} />
          <div className="gradientOverlay"></div>
        </>
      )}
    </div>
  );
};

export default HorizontalImageWithGradient;
