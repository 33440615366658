import React from "react";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";

import { STRING } from "../../Constant/StringConstant";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { IMAGES } from "../../Constant/ImageConstant";
import { MdDeleteOutline } from "react-icons/md";
import { Col, InputGroup, Row } from "react-bootstrap";

const Payment = () => {
  return (
    <div className="Payment focus-in-expand">
      <SubTitle text={STRING.CurrentCard} />
      <br></br>

      <div className="selectedCard slide-in-left-slow">
        <div className="cardDetails">
          <div>
            <p> {STRING.DebitOrCreditCard} </p>
            <p> {STRING.CardNumber} </p>
          </div>
          <img src={IMAGES.CARD_ICON} alt="" />
        </div>

        <div className="deleteSection">
          <MdDeleteOutline />
        </div>
      </div>

      <br></br>

      <div className="addNewCard">
        <SubTitle text={STRING.AddNewCard} />

        <div className="buttonDiv">
          <Button
            classes="px-4 fs-5 fw-bold rounded-4 scale-in-center-slow"
            text={STRING.Add}
            type={INPUT_TYPES.SUBMIT}
          />
        </div>
      </div>

      <br></br>

      <div className="cardForm">

        <InputGroup>
          <Input
            classes="mt-2 mb-2"
            placeholder={STRING.NameOnCard}
            type={INPUT_TYPES.TEXT}
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
          />
        </InputGroup>
        <InputGroup>
          <Input
            classes="mt-2 mb-2 "
            placeholder={STRING.Card_Number}
            type={INPUT_TYPES.TEXT}
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
          />
        </InputGroup>
        <Row>
           <Col md={6} sm={12}>

        <InputGroup>
          <Input
            classes="mt-2 mb-2"
            placeholder={STRING.Expiration}
            type={INPUT_TYPES.TEXT}
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
            />
        </InputGroup>
            </Col>
           <Col md={6} sm={12}>

        <InputGroup>
          <Input
            classes="mt-2 mb-2"
            placeholder={STRING.CVV}
            type={INPUT_TYPES.NUMBER}
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
            />
        </InputGroup>
            </Col>

        </Row>
      </div>
    </div>
  );
};

export default Payment;
