import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../Constant/constants";
import { getToken } from "../../Data/services/localStorageService";

export default function PublicRoute({ children }) {
  var isLoggedIn = getToken(); //todo:
  // var isLoggedIn = false;

  return isLoggedIn ? (
    <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace />
  ) : (
    children
  );
}
