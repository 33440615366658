import React, { useEffect, useState } from 'react';
import { ROUTES } from './Route';

import Dashboard from './Dashboard';
import WorkoutView from './WorkoutView';
import TemplateView from './TemplateView';
import PostView from './PostView';
import WorkoutViewPremium from './WorkoutViewPremium';
import PostViewPremium from './PostViewPremium';
import TemplateViewPremium from './TemplateViewPremium';
import { resetValue } from '../../Data/Features/searchSlice';
import { useDispatch } from 'react-redux';


export default function DASHBOARD() {
    const dispatch = useDispatch()
    const [page, setPage] = useState({
        route: ROUTES.DASHBOARD,
        data: {}
    });

    useEffect(() => {
        return () => dispatch(resetValue())
    }, [])

    switch (page.route) {
        case ROUTES.DASHBOARD:
            return <Dashboard setPage={setPage} />

        case ROUTES.POST_VIEW:
            return <PostView data={page.data} setPage={setPage} />
        case ROUTES.POST_VIEW_PREMIUM:
            return <PostViewPremium data={page.data} setPage={setPage} />

        case ROUTES.WORKOUT_VIEW:
            return <WorkoutView setPage={setPage} data={page.data} />
        case ROUTES.WORKOUT_VIEW_PREMIUM:
            return <WorkoutViewPremium setPage={setPage} data={page.data} />

        // case ROUTES.TEMPLATE_VIEW:
        //     return <TemplateView setPage={setPage} />
        // case ROUTES.TEMPLATE_VIEW_PREMIUM:
        //     return <TemplateViewPremium setPage={setPage} />

        default:
            return <Dashboard setPage={setPage} />
    }


}

