import { createSlice } from "@reduxjs/toolkit";
import { trainingApi } from "../services/trainingApi";
import _ from "lodash";

const initialState = {
    isFetched: false,
    data: {}
};


export const trainingSlice = createSlice({
    name: "training",
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                trainingApi.endpoints.getActiveLog.matchFulfilled,
                (state, { payload }) => {
                    state.isFetched = true;
                    state.data = payload.data;
                }
            )
            .addMatcher(trainingApi.endpoints.getActiveLog.matchRejected, (state) => {
                state = initialState;
            });
        builder
            .addMatcher(
                trainingApi.endpoints.startOrStopLog.matchFulfilled,
                (state, { payload }) => {
                    state.isFetched = true;
                    state.data = !payload?.data?.endedAt ? payload.data : {};
                }
            )
            .addMatcher(trainingApi.endpoints.startOrStopLog.matchRejected, (state) => {
            });
    },
});

export const { resetState } = trainingSlice.actions;

export default trainingSlice.reducer;
