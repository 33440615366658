import { createSlice } from "@reduxjs/toolkit";
import { otherUserApi } from "../services/otherUserApi";

const initialState = {
    data: {},
    post: {
        currentPage: 0,
        data: [],
    },
    workout: {
        currentPage: 0,
        data: [],
    },
    template: {
        currentPage: 0,
        data: [],
    },

};

export const otherUserSlice = createSlice({
    name: "otherUser",
    initialState,
    reducers: {
        resetData: () => {
            return initialState
        },
        followUser: (state, actions) => {
            const slug = actions.payload
            return {
                ...state,
                data: {
                    ...state.data,
                    followers: state.data.followers + 1,
                    followed_slug: slug
                },
            }
        },
        unfollowUser: (state, actions) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    followers: state.data.followers - 1,
                    followed_slug: ''
                },
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                otherUserApi.endpoints.getOtherProfile.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}
                    state.data = payload.data;
                }
            )
            .addMatcher(otherUserApi.endpoints.getOtherProfile.matchRejected, (state) => { });
        builder
            .addMatcher(
                otherUserApi.endpoints.getOtherProfilePost.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    if (params.page === 1) {
                        state.post.currentPage = 1;
                        state.post.data = payload.data;
                    }
                    else if (params.page > state.post.currentPage) {
                        state.post.currentPage = params.page;
                        state.post.data = [...state.post.data, ...payload.data]
                    }
                }
            )
            .addMatcher(otherUserApi.endpoints.getOtherProfilePost.matchRejected, (state) => { });
        builder
            .addMatcher(
                otherUserApi.endpoints.getOtherProfileWorkout.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    if (params.page === 1) {
                        state.workout.currentPage = 1;
                        state.workout.data = payload.data;
                    }
                    else if (params.page > state.workout.currentPage) {
                        state.workout.currentPage = params.page;
                        state.workout.data = [...state.workout.data, ...payload.data]
                    }
                }
            )
            .addMatcher(otherUserApi.endpoints.getOtherProfileWorkout.matchRejected, (state) => { });
    }
});


export const { resetData, followUser, unfollowUser } = otherUserSlice.actions;

export default otherUserSlice.reducer;
