import React, { useEffect, useState } from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  Description,
  Input,
  Title,
  SubTitle,
  Checkbox,
} from "../../Components/CustomComponents";
import { Col, Form, Row } from "react-bootstrap";
import { ACCOUNT_ENUM } from "./Route";
import { useDispatch, useSelector } from "react-redux";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import filterStyles from "../../Components/Modals/styles/filter.module.css";
import { InputGroup } from "react-bootstrap";

import { CiUser } from "react-icons/ci";
import { PiKey } from "react-icons/pi";
import { AiOutlineMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useRegisterUserMutation } from "../../Data/services/userAuthApi";
import { IMAGES } from "../../Constant/ImageConstant";
import Logo from "../../Components/CommonComponents/Logo";
import RegisterYourself from "./Components/RegisterYourself";
import { STRING } from "../../Constant/StringConstant";
import LoginLink from "./Components/LoginLink";
import {
  INPUT_PATTERNS,
  INPUT_TYPES,
} from "../../Constant/InputTypesAndPatternsConstant";
import { RegisterPayload } from "../../Constant/PayloadConstant/RegisterPayloadConstant";
import { MultiSelect } from "react-multi-select-component";
import { useGetLookupQuery, useGetSportsLookupQuery } from "../../Data/services/lookupApi";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import _ from "lodash";
import { requestSportsTypeDTOs } from "../../Data/DTO/registerDTO";

export default function Register({ setPage, setEmail, setAfterRegister }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { lookupData, isFetching, refetch } = useGetSportsLookupQuery({
    refetchOnMountOrArgChange: true,
  });

  const look_data = useSelector((state) => state.lookups.sportsType);

  const loading = [
    { label: "Loading...", value: "Loading...", disabled: true },
  ];

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({});

  // Watch the password field value to compare with confirm password
  const password = watch("password");

  const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);
  const handleSendToOTPVerification = () => setPage(ACCOUNT_ENUM.OTP);

  const [registerUser, { isError, isLoading, isSuccess }] =
    useRegisterUserMutation();

  const handleRegister = async (data) => {
    console.log("handle Register");
    // console.log(data);
    // console.log(data.sports_type);
    data.sports_type = requestSportsTypeDTOs(data.sports_type);
    // console.log(data.sports_type);

    try {
      const response = await registerUser(data);
      console.log(response);

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
        console.log(message);
      }

      if (!response.error && response.data.code === 200) {
        // console.log(response.data.data)
        // Toast('You are registered successfully, Now Login with your credentials', "success");
        SuccessToast(STRING.AfterRegisterSuccessMessage);

        // handleLoginClick();
        setEmail(data.email);
        setAfterRegister(true);
        handleSendToOTPVerification();
      }
    } catch (error) {
      console.log(error);
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("An error occurred during registration", "error");
      }
    }
  };

  return (
    <AccountLayout leftSideImage={IMAGES.LOGIN}>
      {errors
        ? errors?.name?.message
          ? Toast(errors?.name?.message, "error")
          : errors?.email?.message
            ? Toast(errors?.email?.message, "error")
            : errors?.password?.message
              ? Toast(errors?.password?.message, "error")
              : errors?.confirm_password?.message
                ? Toast(errors?.confirm_password?.message, "error")
                : errors?.sports_type?.message
                  ? Toast(errors?.sports_type?.message, "error")
                  : errors?.agree?.message
                    ? Toast(errors?.agree?.message, "error")
                    : null
        : ""}

      <Logo />

      <RegisterYourself />

      <Form
        onSubmit={handleSubmit(handleRegister)}
        className="w-100 scale-in-center"
      >
        <Controller
          control={control}
          name={RegisterPayload.Name}
          rules={{
            required: STRING.FullNameRequired,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.FullName}
                type={INPUT_TYPES.TEXT}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.Email}
          rules={{
            required: STRING.EmailRequired,
            pattern: {
              value: INPUT_PATTERNS.EMAIL_PATTERNS,
              message: STRING.ValidEmailRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder={STRING.EmailAddress}
                type={INPUT_TYPES.EMAIL}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.SportsType}
          rules={{
            required: "Must be select a sports type.",
          }}
          defaultValue={[]}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <MultiSelect
              options={look_data ?? loading}
              value={value}
              onChange={onChange}
              labelledBy="Select Sports Type"
              hasSelectAll={false}
              disableSearch={true}
            />
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.Password}
          rules={{
            required: STRING.PasswordRequired,
            minLength: {
              value: 6,
              message: STRING.PasswordSixCharacterRequired,
            },
            pattern: {
              value: INPUT_PATTERNS.PASSWORD_PATTERNS,
              message: STRING.PasswordPatternRequired,
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.CreatePassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name={RegisterPayload.ConfirmPassword}
          rules={{
            required: STRING.ConfirmPasswordRequired,
            minLength: {
              value: 6,
              message: STRING.ConfirmPasswordSixCharacterRequired,
            },
            pattern: {
              value: INPUT_PATTERNS.PASSWORD_PATTERNS,
              message: STRING.ConfirmPasswordPatternRequired,
            },
            validate: (value) =>
              value === password || STRING.ConfirmPasswordMustMatch,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder={STRING.ConfirmPassword}
                type={INPUT_TYPES.PASSWORD}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="agree"
          rules={{
            required: "Must be agree with Condition and Privacy Policy.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Checkbox
              label={STRING.ContinueWithSocialLogin}
              secondLabel={STRING.TermsAndCondition}
              classes="mt-2 mb-2"
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />

        <Button
          classes="mt-4 mb-2 puff-in-hor"
          text={isLoading ? STRING.Sending : STRING.Register}
          type={INPUT_TYPES.SUBMIT}
          disabled={isLoading}
        />
      </Form>

      <LoginLink handleLoginClick={handleLoginClick} />
    </AccountLayout>
  );
}
