import React from "react";

const MenuSvgIcon = ({ color = "white" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86482 8.8648C6.89993 8.8648 5.30201 7.27445 5.30201 5.31887C5.30201 3.3633 6.89993 1.77295 8.86482 1.77295C10.8297 1.77295 12.4276 3.3633 12.4276 5.31887C12.4276 7.27445 10.8297 8.8648 8.86482 8.8648ZM12.2121 9.46138C13.6256 8.32934 14.4495 6.49873 14.1467 4.4944C13.7949 2.16916 11.8513 0.308475 9.50523 0.0372117C6.26753 -0.33777 3.52061 2.17098 3.52061 5.31887C3.52061 6.99432 4.30086 8.48713 5.51756 9.46138C2.52836 10.5792 0.346138 13.2041 0.00410801 16.7465C-0.0457707 17.2704 0.364847 17.7296 0.893924 17.7296C1.34729 17.7296 1.73386 17.3892 1.77394 16.9398C2.13111 12.9834 5.17464 10.6378 8.86482 10.6378C12.555 10.6378 15.5985 12.9834 15.9557 16.9398C15.9958 17.3892 16.3823 17.7296 16.8357 17.7296C17.3648 17.7296 17.7754 17.2704 17.7255 16.7465C17.3835 13.2041 15.2013 10.5792 12.2121 9.46138Z"
        fill={color}
      />
    </svg>
  );
};

export default MenuSvgIcon;
