import React from 'react';
import { SubTitle } from '../../Components/CustomComponents';
import { STRING } from '../../Constant/StringConstant';


import AdditionalInfoForm from './Components/AdditionalInfoForm';
import ChangePasswordForm from './Components/ChangePasswordForm';
import ProfileForm from './Components/ProfileForm';

const Setting = ({ }) => {


    return (
        <div className='Setting focus-in-expand'>
            <SubTitle text={STRING.Settings} />
            <br></br>
            <div className='mainContainer'>
                <ProfileForm />

                <ChangePasswordForm />

                <AdditionalInfoForm />
            </div>
            <br></br>
        </div>
    )
}

export default Setting
