import React from 'react'

const UserAvatarWithNameAndTag = ({ avatarImage, userName, userTag, time = '', handleOnClick = () => { }, size = 'md' }) => {
    return (

        (size === "sm") ?
            <div className='d-flex flex-row justify-content-between align-items-end cursor-pointer' onClick={handleOnClick} >
                <div className='d-flex align-items-center'>

                    <img src={avatarImage} alt="" width={40} height={40} style={{ borderRadius: '100%', objectFit: "cover" }} />

                    <div className='d-flex flex-column mx-2 align-items-center h-100'>

                        {
                            !userName ? "" : <p className='fw-bold m-0 fs-7 text-white'> {userName}  {time ? <span className='fs-9 text-granite ms-1' >{time}</span> : ''}  </p>

                        }
                        {
                            userTag ?
                                <p className=' m-0 fs-8 text-granite'> {userTag} </p>
                                : null
                        }

                    </div>
                </div>
            </div > :

            <div className='d-flex flex-row justify-content-between align-items-end cursor-pointer' onClick={handleOnClick} >
                <div className='d-flex align-items-center'>

                    <img src={avatarImage} alt="" width={50} height={50} style={{ borderRadius: '100%', objectFit: "cover" }} />

                    <div className='d-flex flex-column mx-2 align-items-center h-100'>

                        {
                            !userName ? "" : <p className='fw-bold m-0 fs-6 text-white'> {userName}  {time ? <span className='fs-9 text-granite ms-1' >{time}</span> : ''}  </p>

                        }
                        {
                            userTag ?
                                <p className=' m-0 fs-7 text-granite'> {userTag} </p>
                                : null
                        }

                    </div>
                </div>
            </div >
    )
}

export default UserAvatarWithNameAndTag


