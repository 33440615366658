import React, { useState } from "react";
import { ROUTES } from "./Route";
import Privacy from "./Privacy";
import TermsAndCondition from "./TermsAndCondition";
import {
  useGetPrivacyQuery,
  useGetTermsConditionQuery,
} from "../../Data/services/staticContentApi";

export default function PAYMENT() {
  const [page, setPage] = useState(ROUTES.PRIVACY);

  const { privacyData, isFetching, refetch } = useGetPrivacyQuery();
  const {
    termsData,
    isFetching: t_isFetching,
    refetch: t_refetch,
  } = useGetTermsConditionQuery();

  switch (page) {
    case ROUTES.PRIVACY:
      return <Privacy setPage={setPage} />;
    case ROUTES.TERMS:
      return <TermsAndCondition setPage={setPage} />;

    default:
      return <Privacy setPage={setPage} />;
  }
}
