import { END_POINTS } from "../../Constant/EndPointsContants";
import { baseApi } from "./baseApi";

export const dashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPost: builder.mutation({
      query: (data) => {
        return {
          url: END_POINTS.Dashboard.CREATE_POST,
          method: "POST",
          body: data,
        };
      },
    }),
    createWorkOut: builder.mutation({
      query: (data) => {
        return {
          url: END_POINTS.Dashboard.CREATE_WORKOUT,
          method: "POST",
          body: data,
        };
      },
    }),
    createForum: builder.mutation({
      query: (data) => {
        return {
          url: END_POINTS.Dashboard.CREATE_FORUM,
          method: "POST",
          body: data,
        };
      },
    }),
    createTemplate: builder.mutation({
      query: (data) => {
        return {
          url: END_POINTS.Dashboard.CREATE_TEMPLATE,
          method: "POST",
          body: data,
        };
      },
    }),
    updateTemplate: builder.mutation({
      query: ({ slug, data }) => {
        return {
          url: END_POINTS.Dashboard.UPDATE_TEMPLATE(slug),
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (_, __, { slug }) => ([`Dashboard/TEMPLATE/${slug}/Details`, 'Profile/Template'])
    }),
    getAll: builder.query({
      query: ({ is_paid, search = '', page = 1, offset = 0, limit = 18 }) => {
        let url = END_POINTS.Dashboard.GET_ALL + `?page=${page}&offset=${offset}&limit=${limit}&search=${search}`
        if (is_paid !== '') {
          url += `&is_paid=${is_paid ? "1" : "0"}`
        }
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, { is_paid }) => {
        let tags = '';
        if (is_paid !== '')
          tags = is_paid ? "paid" : "free"

        return ['Dashboard', 'All/' + tags]
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),

    getPost: builder.query({
      query: ({ is_paid, search = '', page = 1, offset = 0, limit = 18 }) => {
        let url = END_POINTS.Dashboard.GET_POST + `?page=${page}&offset=${offset}&limit=${limit}&search=${search}`
        if (is_paid !== '') {
          url += `&is_paid=${is_paid ? "1" : "0"}`
        }
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, { is_paid }) => {
        let tags = '';
        if (is_paid !== '')
          tags = is_paid ? "paid" : "free"

        return ['Dashboard', 'Post/' + tags]
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    getPostDetails: builder.query({
      query: (slug) => {
        let url = END_POINTS.Dashboard.GET_POST_DETAILS + slug
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, slug) => {
        return ['Dashboard', 'POST', slug, 'Details']
      },
      refetchOnFocus: true,
    }),

    getWorkout: builder.query({
      query: ({ is_paid, search = '', page = 1, offset = 0, limit = 18 }) => {
        let url = END_POINTS.Dashboard.GET_WORKOUT + `?page=${page}&offset=${offset}&limit=${limit}&search=${search}`
        if (is_paid !== '') {
          url += `&is_paid=${is_paid ? "1" : "0"}`
        }
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, { is_paid }) => {
        let tags = '';
        if (is_paid !== '')
          tags = is_paid ? "paid" : "free"

        return ['Dashboard', 'Workout/' + tags]
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    getWorkoutDetails: builder.query({
      query: (slug) => {
        let url = END_POINTS.Dashboard.GET_WORKOUT_DETAILS + slug
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, slug) => {
        return ['Dashboard', 'Workout', slug, 'Details']
      },
      refetchOnFocus: true,
    }),

    getTemplate: builder.query({
      query: ({ is_paid, search = '', page = 1, offset = 0, limit = 18 }) => {
        let url = END_POINTS.Dashboard.GET_TEMPLATE + `?page=${page}&offset=${offset}&limit=${limit}&search=${search}`
        if (is_paid !== '') {
          url += `&is_paid=${is_paid ? "1" : "0"}`
        }
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, { is_paid }) => {
        let tags = '';
        if (is_paid !== '')
          tags = is_paid ? "paid" : "free"

        return ['Dashboard', 'Template/' + tags]
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
    getTemplateDetails: builder.query({
      query: (slug) => {
        let url = END_POINTS.Dashboard.GET_TEMPLATE_DETAILS + slug
        return {
          url: url,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: (_, __, slug) => {
        return [`Dashboard/TEMPLATE/${slug}/Details`]
      },
      refetchOnFocus: true,
    }),
    downloadTemplate: builder.query({
      query: (slug) => {
        return {
          url: END_POINTS.Dashboard.DOWNLOAD_TEMPLATE(slug),
          method: "GET",
          headers: {
            "Content-type": "multipart/form-data",
          },
        };
      },
    }),

  })
});

export const {
  useCreatePostMutation,
  useCreateWorkOutMutation,
  useCreateForumMutation,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useGetAllQuery,
  useGetPostQuery,
  useGetPostDetailsQuery,
  useGetWorkoutQuery,
  useGetWorkoutDetailsQuery,
  useGetTemplateQuery,
  useGetTemplateDetailsQuery,
  useDownloadTemplateQuery,
} = dashboardApi;

// export const dashboardApi = createApi({
//   reducerPath: "dashboardApi",
//   baseQuery: fetchBaseQuery({
//     baseUrl: API_URL,
//     prepareHeaders: (headers, { getState }) => {
//       let auth_token = getToken();
//       if (auth_token) {
//         headers.set("authorization", `Bearer ${ auth_token } `);
//       }
//       return headers;
//     },
//   }),
//   endpoints: (builder) => ({
//     createPost: builder.mutation({
//       query: (data) => ({
//         url: END_POINTS.Dashboard.CREATE_POST,
//         method: "POST",
//         body: data,
//       }),
//     }),
//   }),
// });

// export const { useCreatePostMutation } = dashboardApi;
