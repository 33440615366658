import React, { useEffect, useRef, useState } from 'react'
import CustomNavbar from './Components/CustomNavbar'
import CommunityPostWithComment from './Components/CommunityPostWithComment';
import { STRING } from '../../Constant/StringConstant';
import CommunityPost from './Components/CommunityPost';
import CommunityFollowingPost from './Components/CommunityFollowingPost';
import ForYouPost from './Components/ForYouPost';

const Community = () => {
    const [activeLink, setActiveLink] = useState(STRING.Following);

    const handleChangeActive = (link) => {
        console.log(link)
        setActiveLink(link);
    };

    return (
        <div className='Community' >

            {
                // MARK: COMMUNITY NAVBAR
            }
            <div className='Community-Navbar'>
                <CustomNavbar activeLink={activeLink} handleChangeActive={handleChangeActive} />
            </div>

            {
                // MARK: COMMUNITY POSTS
            }

            <div className='pb-4 custom-scrollbar'>

                {
                    activeLink === STRING.Following ?
                        <CommunityFollowingPost />
                        : ''

                }

                {
                    activeLink === STRING.ForYou ?
                        <ForYouPost />
                        : ''
                }

                {
                    activeLink === STRING.Forum ?
                        <CommunityPost />
                        : ''
                }

            </div>



        </div>
    )
}

export default Community
