import React from 'react';

const TemplateSign = ({ text }) => {
    return (
        <div className='templateTag'>
            <span>{text}</span>
        </div>
    )
}

export default TemplateSign
