import React, { useState } from "react";
import filterStyles from "./selectFieldComponent.module.css";

const SelectFieldComponent = ({
  optionsList,
  firstOption,
  onSelectChange,
  style,
  disabled = false,
  className,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onSelectChange(value); // Send selected value to parent
  };

  return (
    <div style={{ width: "100%" }}>
      <select
        style={style}
        className={className ? className : `${filterStyles.select}`}
        value={selectedValue}
        onChange={handleSelectChange}
        disabled={disabled}
      >
        <option value="" disabled hidden>
          {firstOption}
        </option>
        {optionsList.map((value, index) => (
          <option value={value} key={index}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectFieldComponent;
