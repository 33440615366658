import React from "react";

const SingleChatThread = ({
  handleThreadClick,
  image,
  name,
  lastMsg,
  lastTime,
  isOnline,
  unReadMsg,
}) => {
  return (
    <div >
      <div onClick={handleThreadClick} className="cursor-pointer">
        <div className="my-2 overflow-visible thread-card">
          <div className="thread">
            <div className="imageDiv">
              <img className="image" src={image} alt="User Profile" />
              {isOnline ? <span className="onlineIndicator"></span> : ""}
            </div>
            <div className="userInfo">
              <div className="nameContainer">
                <p className="text-white fs-5 lc-1 m-0 pe-1 lc-1">{name}</p>
                <p className={`message m-0 pe-1 lc-1`}>{lastMsg}</p>
              </div>
              {unReadMsg === true ? <span className="indicator"></span> : ""}
            </div>
          </div>
          <p className="fs-8 last-time">{lastTime}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleChatThread;
