import React from 'react'
import { SVG } from '../../../Constant/SvgConstant'

const PremiumSign = () => {
    return (
        <div className='premium scale-in-center'>
            <img src={SVG.CROWN_ICON} alt="" />
        </div>
    )
}

export default PremiumSign
