import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../services/userApi";

const initialState = {
  email: "",
  name: "",
  permissions: [], // this is direct permission | permissions.map((permiison)=>{permission.name})
  roles: [], // this is roles and permission | roles -> role.name | roles.permissions.map((permiison)=>{permission.name})
  users: {
    currentPage: 0,
    data: [],
  },
  my_post: {
    currentPage: 0,
    data: [],
  },
  my_workout: {
    currentPage: 0,
    data: [],
  },
  my_template: {
    currentPage: 0,
    data: [],
  },
  my_shared_post: {
    currentPage: 0,
    data: [],
    comment: {}
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
    },
    unsetUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
    },
    updateUsers: (state, action) => {
      // console.log('update Users' + action.payload);
      state.users = action.payload;
    },
    likePost: (state, actions) => {
      const slug = actions.payload
      return {
        ...state,
        my_shared_post: {
          ...state.my_shared_post,
          data: state.my_shared_post.data.map(item => {
            const post_slug = item.is_shared ? item.post_slug : item.slug
            if (post_slug !== slug) return item;
            return {
              ...item,
              likes: item.likes + 1,
              is_liked: true
            }
          })
        },
      }
    },
    removePostLike: (state, actions) => {
      const slug = actions.payload
      return {
        ...state,
        my_shared_post: {
          ...state.my_shared_post,
          data: state.my_shared_post.data.map(item => {
            const post_slug = item.is_shared ? item.post_slug : item.slug
            if (post_slug !== slug) return item;
            return {
              ...item,
              likes: item.likes - 1,
              is_liked: false
            }
          })
        },
      }
    },
    savePost: (state, actions) => {
      const slug = actions.payload
      return {
        ...state,
        my_shared_post: {
          ...state.my_shared_post,
          data: state.my_shared_post.data.map(item => {
            const post_slug = item.is_shared ? item.post_slug : item.slug
            if (post_slug !== slug) return item;
            return {
              ...item,
              is_saved: true
            }
          })
        },
      }
    },
    unsavePost: (state, actions) => {
      const slug = actions.payload
      return {
        ...state,
        my_shared_post: {
          ...state.my_shared_post,
          data: state.my_shared_post.data.map(item => {
            const post_slug = item.is_shared ? item.post_slug : item.slug
            if (post_slug !== slug) return item;
            return {
              ...item,
              is_saved: false
            }
          })
        },
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.getMyPost.matchFulfilled,
        (state, { payload, meta }) => {
          const params = meta.arg.originalArgs || {}

          if (params.page === 1) {
            state.my_post.currentPage = 1;
            state.my_post.data = payload.data;
          }
          else if (params.page > state.my_post.currentPage) {
            state.my_post.currentPage = params.page;
            state.my_post.data = [...state.my_post.data, ...payload.data]
          }
        }
      )
      .addMatcher(userApi.endpoints.getMyPost.matchRejected, (state) => { });
    builder
      .addMatcher(
        userApi.endpoints.getMyWorkout.matchFulfilled,
        (state, { payload, meta }) => {
          const params = meta.arg.originalArgs || {}

          if (params.page === 1) {
            state.my_workout.currentPage = 1;
            state.my_workout.data = payload.data;
          }
          else if (params.page > state.my_workout.currentPage) {
            state.my_workout.currentPage = params.page;
            state.my_workout.data = [...state.my_workout.data, ...payload.data]
          }
        }
      )
      .addMatcher(userApi.endpoints.getMyWorkout.matchRejected, (state) => { });
    builder
      .addMatcher(
        userApi.endpoints.getAllUsers.matchFulfilled,
        (state, { payload, meta }) => {
          const params = meta.arg.originalArgs || {}

          if (params.page === 1) {
            state.users.currentPage = 1;
            state.users.data = payload.data;
          }
          else if (params.page > state.users.currentPage) {
            state.users.currentPage = params.page;
            state.users.data = [...state.users.data, ...payload.data]
          }
        }
      )
      .addMatcher(userApi.endpoints.getAllUsers.matchRejected, (state) => { });
    builder
      .addMatcher(
        userApi.endpoints.getMySharedPost.matchFulfilled,
        (state, { payload, meta }) => {
          const params = meta.arg.originalArgs || {}

          if (params.page === 1) {
            state.my_shared_post.currentPage = 1;
            state.my_shared_post.data = payload.data;
          }
          else if (params.page > state.my_shared_post.currentPage) {
            state.my_shared_post.currentPage = params.page;
            state.my_shared_post.data = [...state.my_shared_post.data, ...payload.data]
          }
        }
      )
      .addMatcher(userApi.endpoints.getMySharedPost.matchRejected, (state) => { });

    builder
      .addMatcher(
        userApi.endpoints.getPostComment.matchFulfilled,
        (state, { payload, meta }) => {
          const params = meta.arg.originalArgs || {}
          state.my_shared_post.comment[params.slug] = payload.data
        }
      )
      .addMatcher(userApi.endpoints.getPostComment.matchRejected, (state) => { });
    builder
      .addMatcher(
        userApi.endpoints.sendPostComment.matchFulfilled,
        (state, { payload, meta }) => {
          const params = meta.arg.originalArgs || {}
          state.my_shared_post.comment[params.Post_slug] = [
            ...(state.my_shared_post.comment[params.Post_slug] || []),
            payload.data
          ]
          state.my_shared_post.data = state.my_shared_post.data.map(item => {
            const post_slug = item.is_shared ? item.post_slug : item.slug
            if (post_slug !== params.Post_slug) return item;
            return {
              ...item,
              comments: item.comments + 1,
            }
          })

        }
      )
      .addMatcher(userApi.endpoints.sendPostComment.matchRejected, (state) => { });
  }
});


export const { setUserInfo, unsetUserInfo, updateUsers, likePost, removePostLike, savePost, unsavePost } = userSlice.actions;

export default userSlice.reducer;
