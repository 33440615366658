import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { STRING } from "../../Constant/StringConstant";
import { useGetRemainingUsersQuery } from "../../Data/services/userApi";
import { Button } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import MemberSelectionCard from "./MemberSelectionCard";


const AddMemberInGroupModal = ({ chat_room_slug = '', handleModalClose }) => {
  const [modalClass, setModalClass] = useState(
    "addMemberInGroupModalContainer scale-in-hor-center"
  );

  const [selectedMembers, setSelectedMembers] = useState([]);


  const LIMIT = useMemo(() => 20, []);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [skip, setSkip] = useState(false)
  const [isFetchedFirstTime, setIsFetchedFirstTime] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { data, isFetching } = useGetRemainingUsersQuery({ page: currentPage, search, chat_room_slug, limit: LIMIT, offset }, {
    skip: skip,
    refetchOnMountOrArgChange: false,
  });
  const [users, setUsers] = useState({
    currentPage: 0,
    data: [],
  })


  useEffect(() => {

    if (isFetching) return;
    setIsFetchedFirstTime(true);
    setSkip(true)

    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))

    }


  }, [isFetching])

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
      setSkip(false)
    }
  }, [inView])

  useEffect(() => {
    if (!isFetchedFirstTime) return;
    const timeout = setTimeout(() => {
      setCurrentPage(1)
      setOffset(0)
      setTotal(0)
      setSkip(false)
    }, 400)

    return () => clearTimeout(timeout)
  }, [search])

  useEffect(() => {
    if (isFetching) return;

    if (data.links?.current === 1) {
      setUsers({
        currentPage: 1,
        data: data?.data
      })
    }
    else {
      setUsers(prev => {
        return {
          currentPage: data.links?.current,
          data: [...prev?.data, ...data?.data]
        }
      })
    }
  }, [data])


  const handleSelect = (slug) => {
    setSelectedMembers((prevSelectedMembers) => {
      if (_.includes(prevSelectedMembers, slug)) {
        return _.without(prevSelectedMembers, slug);
      } else {
        return _.concat(prevSelectedMembers, slug);
      }
    });
  }

  const handleModalCloseWithAnimation = () => {
    setModalClass("addMemberInGroupModalContainer scale-out-horizontal h-100 overflow-hidden");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="h-100 w-100  d-flex flex-column">
            <div className="h-100 w-100 overflow-hidden">

              <div className="title-container d-flex align-items-center w-100 justify-content-between">
                <p className="fs-4 fw-medium m-0">{STRING.AddMembers}</p>

                <div className={"closeBtn"}>
                  <FaCircleXmark
                    fontSize={40}
                    color={"#F68B28"}
                    onClick={handleModalCloseWithAnimation}
                  />
                </div>
              </div>

              <br></br>
              {
                // <div className="d-flex flex-row justify-content-start w-100 my-2 mb-4">
                //   <p className="counter">{selectedMembers.length} Members </p>
                // </div>
              }
              <div className="users-container">
                <div className="chat-container">

                  {
                    (users?.data ?? []).map((member, index) => (
                      <>
                        {index > 0 ? <hr></hr> : ""}
                        <div
                          ref={
                            (users?.data.length >= (total - offset)) ? null :
                              (index === (users?.data.length - 1)) ? ref : null}
                        >
                          <MemberSelectionCard
                            key={index}
                            isSelected={_.includes(selectedMembers, member.slug)}
                            onSelect={() => handleSelect(member.slug)}
                            data={member}
                          />
                        </div >
                      </>
                    ))
                  }
                  {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}

                </div>

              </div>
              <br></br>

              <div className="fixed-button">
                <Button
                  classes="scale-in-center-slow fs-6 my-0 rounded-4"
                  text={STRING.AddMembers}
                  type={INPUT_TYPES.SUBMIT}
                // onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalContainer >
    </div >
  );
};

export default AddMemberInGroupModal;
