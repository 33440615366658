import { createSlice } from "@reduxjs/toolkit";
import { dashboardApi } from "../services/dashboardApi";
import _ from "lodash";

const initialState = {
    all: {
        all: {
            currentPage: 0,
            data: [],
        },
        free: {
            currentPage: 0,
            data: [],
        },
        paid: {
            currentPage: 0,
            data: [],
        }
    },
    post: {
        all: {
            currentPage: 0,
            data: [],
        },
        free: {
            currentPage: 0,
            data: [],
        },
        paid: {
            currentPage: 0,
            data: [],
        }
    },
    workout: {
        all: {
            currentPage: 0,
            data: [],
        },
        free: {
            currentPage: 0,
            data: [],
        },
        paid: {
            currentPage: 0,
            data: [],
        }
    },
    template: {
        all: {
            currentPage: 0,
            data: [],
        },
        free: {
            currentPage: 0,
            data: [],
        },
        paid: {
            currentPage: 0,
            data: [],
        }
    }
};

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                dashboardApi.endpoints.getAll.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    const key = (params.is_paid === '') ? "all" : params.is_paid ? 'paid' : "free";
                    if (params.page === 1) {
                        state.all[key].currentPage = 1;
                        state.all[key].data = payload.data;
                    }
                    else if (params.page > state.all[key].currentPage) {
                        state.all[key].currentPage = params.page;
                        state.all[key].data = [...state.all[key].data, ...payload.data]
                    }
                }
            )
            .addMatcher(dashboardApi.endpoints.getAll.matchRejected, (state) => { });

        builder
            .addMatcher(
                dashboardApi.endpoints.getPost.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    const key = (params.is_paid === '') ? "all" : params.is_paid ? 'paid' : "free";
                    if (params.page === 1) {
                        state.post[key].currentPage = 1;
                        state.post[key].data = payload.data;
                    }
                    else if (params.page > state.post[key].currentPage) {
                        state.post[key].currentPage = params.page;
                        state.post[key].data = [...state.post[key].data, ...payload.data]
                    }
                }
            )
            .addMatcher(dashboardApi.endpoints.getPost.matchRejected, (state) => { });
        builder
            .addMatcher(
                dashboardApi.endpoints.getWorkout.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    const key = (params.is_paid === '') ? "all" : params.is_paid ? 'paid' : "free";
                    if (params.page === 1) {
                        state.workout[key].currentPage = 1;
                        state.workout[key].data = payload.data;
                    }
                    else if (params.page > state.workout[key].currentPage) {
                        state.workout[key].currentPage = params.page;
                        state.workout[key].data = [...state.workout[key].data, ...payload.data]
                    }
                }
            )
            .addMatcher(dashboardApi.endpoints.getWorkout.matchRejected, (state) => { });
        builder
            .addMatcher(
                dashboardApi.endpoints.getTemplate.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    const key = (params.is_paid === '') ? "all" : params.is_paid ? 'paid' : "free";
                    if (params.page === 1) {
                        state.template[key].currentPage = 1;
                        state.template[key].data = payload.data;
                    }
                    else if (params.page > state.template[key].currentPage) {
                        state.template[key].currentPage = params.page;
                        state.template[key].data = [...state.template[key].data, ...payload.data]
                    }
                }
            )
            .addMatcher(dashboardApi.endpoints.getTemplate.matchRejected, (state) => { });
    },
});

export const { } = dashboardSlice.actions;

export default dashboardSlice.reducer;
