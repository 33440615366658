import React, { useState } from 'react';
import { FaCircleXmark } from 'react-icons/fa6';
import { STRING } from '../../Constant/StringConstant';
import ModalContainer from '../ModalContainer';
// import cssStyles from './styles/notification.module.css';

const AddModal = ({ handleAddModalClose, handleOpenModal }) => {
    const [modalClass, setModalClass] = useState('addModalContainer scale-in-hor-center');

    const handleModalClose = () => {
        setModalClass('addModalContainer scale-out-horizontal');
        setTimeout(() => {
            handleAddModalClose();
        }, 500);
    };

    const handleModalChange = (number) => {
        handleModalClose();

        handleOpenModal(number);

    }

    return (
        <div>
            <ModalContainer
                handleClose={handleModalClose}
            >
                <div className={modalClass}>
                    {
                        // <div className='d-flex align-items-center w-100 justify-content-end'>
                        // <p className='fs-4 fw-medium m-0'
                        //         Notification
                        //     </p>
                        // </div>
                    }
                    <div className={'closeBtn'}>
                        <FaCircleXmark fontSize={40} color={'#F68B28'} onClick={handleModalClose} />
                    </div>

                    <br></br>

                    <div className='d-flex align-items-center w-100 justify-content-start' role='button' onClick={() => { handleModalChange(1) }}>
                        <div className='ms-3'>
                            <p className='fs-4 fw-semibold m-0'>{STRING.CreatePost}</p>
                            <p className='m-0 fs-7 description'> {STRING.Description1} </p>
                        </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex align-items-center w-100 justify-content-start' role='button' onClick={() => { handleModalChange(2) }}>
                        <div className='ms-3'>
                            <p className='fs-4 fw-semibold m-0'>{STRING.CreateWorkout}</p>
                            <p className='m-0 fs-7 description'> {STRING.Description2} </p>
                        </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex align-items-center w-100 justify-content-start' role='button' onClick={() => { handleModalChange(3) }}>
                        <div className='ms-3'>
                            <p className='fs-4 fw-semibold m-0'>{STRING.LogTrainingDay}</p>
                            <p className='m-0 fs-7 description'> {STRING.Description3} </p>
                        </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex align-items-center w-100 justify-content-start' role='button' onClick={() => { handleModalChange(4) }}>
                        <div className='ms-3'>
                            <p className='fs-4 fw-semibold m-0'>{STRING.CreateForum}</p>
                            <p className='m-0 fs-7 description'> {STRING.Description4} </p>
                        </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex align-items-center w-100 justify-content-start' role='button' onClick={() => { handleModalChange(5) }}>
                        <div className='ms-3'>
                            <p className='fs-4 fw-semibold m-0'>{STRING.WorkOutTemplate}</p>
                            <p className='m-0 fs-7 description'> {STRING.Description5} </p>
                        </div>
                    </div>

                    <br></br>

                </div>
            </ModalContainer>
        </div>
    )

}

export default AddModal