import React from "react";
import { Col, Row } from "react-bootstrap";
import { IMAGES } from "../../../Constant/ImageConstant";
import { STRING } from "../../../Constant/StringConstant";
import { SVG } from "../../../Constant/SvgConstant";
import styles from "../styles/index.module.css";

const videosList = [
  {
    video: true,
    img: "/images/my-posts/post-one.png",
  },
  {
    video: false,
    img: "/images/my-posts/post-two.png",
  },
  {
    video: false,
    img: "/images/my-posts/post-three.png",
  },
  {
    video: false,
    img: "/images/my-posts/post-one.png",
  },
  {
    video: false,
    img: "/images/my-posts/post-one.png",
  },
  {
    video: false,
    img: "/images/my-posts/post-two.png",
  },
];

var workoutList = [
  {
    image: IMAGES.POST_IMAGE,
    title: STRING.Title,
    video: false,
    workout: true,
    premium: false,
    template: false,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    image: IMAGES.POST_IMAGE,
    title: STRING.Title,
    video: true,
    workout: false,
    premium: true,
    template: false,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    image: IMAGES.POST_IMAGE,
    title: STRING.Title,
    video: true,
    workout: false,
    premium: false,
    template: false,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
  {
    image: IMAGES.POST_IMAGE,
    title: STRING.Title,
    video: true,
    workout: false,
    premium: false,
    template: false,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

export default function MyPurchase({ setPage }) {
  const handlePageView = (premium) => {};

  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <h4>Videos</h4>
        </Col>
        <Col xs={12} md={12} className="p-0">
          <div className="d-flex flex-wrap">
            <div className="grid_post">
              {videosList.map((item, index) => (
                <Videos item={item} key={index} />
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="g-3">
        <Col xs={12}>
          <h4>Workouts</h4>
        </Col>
        {workoutList.map((item, index) => (
          <Col xs={12} md={6} key={index}>
            <WorkoutView item={item} />
          </Col>
        ))}
      </Row>

      <Row className="g-3 mt-4">
        <Col xs={12}>
          <h4>Templates</h4>
        </Col>
        {workoutList.map((item, index) => (
          <Col xs={12} md={6} key={index}>
            <Templates item={item} />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
}

const Videos = ({ item: { img, video } }) => {
  return (
    <div style={{ position: "relative" }}>
      <img alt="My post" src={img} />
      <div className={styles.lightPlayBtn}>
        {video && <img src={SVG.LIGHT_PLAY_ICON} alt="" />}
      </div>
    </div>
  );
};

const WorkoutView = (props) => {
  const { image, title, premium } = props.item;

  return (
    <div className="workoutContainer" role="button">
      <div className="imageWrapper">
        <img src={image} alt="" className={`image `} />
        {/* ${premium ? "premiumImage" : ""} */}
        <div className="gradientOverlay"></div>
      </div>

      {premium ? <PremiumTxt /> : ""}

      {/* <FreeTag text={"Free"} /> */}

      <p className="title lc-1 m-0 fs-5 mb-5"> {title} </p>
      <div className={styles.workout_styles}>
        <div className={`scale-in-center ${styles.tag_btn}`}>Dribbling</div>
        <div className={`scale-in-center ${styles.tag_btn}`}>
          Basketball Techniques
        </div>
      </div>
    </div>
  );
};

const PremiumTxt = () => {
  return <div className={`scale-in-center ${styles.premium}`}>Premium</div>;
};

const Templates = (props) => {
  const { image, title, premium, description } = props.item;

  const handleViewPage = () => {
    if ("isPremium") {
      // openPremiumTemplate();
    } else {
      // openFreeTemplate();
    }
  };

  return (
    <div className="templateContainer" onClick={handleViewPage} role="button">
      <div>
        <h3 className="title lc-1 m-0 fs-4 flex-wrap mb-3">{title}</h3>
        <p className={`lc-2 m-0 fs-6 ${styles.description}`}> {description} </p>
      </div>
    </div>
  );
};
