import { React, useRef } from "react";
import { IMAGES } from "../../../Constant/ImageConstant";
// import { useDraggable } from "react-use-draggable-scroll";

const GroupUsersAvatar = ({ groupMembers = [] }) => {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  // const { events } = useDraggable(ref,
  //   {
  //       applyRubberBandEffect: true, // activate rubber band effect
  //       decayRate: 0.96, // specify the decay rate
  //       safeDisplacement: 11, // specify the drag sensitivity
  //     }
  //   ); // Now we pass the reference to the useDraggable hook:


  return (

    <div
      style={{ position: 'relative', display: "inline-flex", overflow: 'hidden', width: 'fit-content', height: '60px', margin: "0 auto" }}
      className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
      // {...events}  
      ref={ref} // add reference and events to the wrapping div
    >
      {(groupMembers?.slice(0, 6)).map((member, index) => (
        index < 5 ?
          <div key={index} style={{ position: "relative", right: 14 * index + 'px', zIndex: index }}>
            <img src={member.image_url} alt={"Team Logo"} width="45px" height="45px" className="rounded-5" style={{}} />
          </div>
          :
          <div key={index} style={{ position: "relative", right: 14 * index + 'px', zIndex: index }}>
            <div className={` existingLogo `} style={{ width: "45px", height: "45px" }}>
              +{groupMembers.length - 5}
            </div>
          </div>
      ))}
    </div>
  );
};

export default GroupUsersAvatar;
