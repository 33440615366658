import React, { useState } from 'react'
import { Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Button, Input, SubTitle } from '../../../Components/CustomComponents';
import { Controller, useForm } from 'react-hook-form';
import { STRING } from '../../../Constant/StringConstant';
import { INPUT_PATTERNS, INPUT_TYPES } from '../../../Constant/InputTypesAndPatternsConstant';
import { useChangePasswordMutation } from '../../../Data/services/userAuthApi';
import { logout } from '../../../Data/Features/authSlice';
import { removeToken } from '../../../Data/services/localStorageService';
import { ErrorToast } from '../../../Hooks/useToast';
import { useDispatch } from 'react-redux';


const ChangePasswordForm = ({ }) => {
    const dispatch = useDispatch()
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [changePassword] = useChangePasswordMutation();

    const {
        handleSubmit,
        control,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onTouched"
        // defaultValues:{
        //     email: '',
        //     password : '',
        // }
    });
    const watchFields = watch("new_password")
    const handleChangePassword = async (data) => {
        try {
            setIsSubmitted(true)
            const response = await changePassword(data).unwrap();
            dispatch(logout())
            removeToken()
            window.location.reload();
        }
        catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            else {
                ErrorToast("An error occurred during change password");
            }
            setIsSubmitted(false)
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(handleChangePassword)} className='mb-5'>
                <div className='d-flex flex-row justify-content-between align-items-end flex-wrap mb-3'>
                    <SubTitle text={STRING.ChangePassword} />

                    <div className="buttonDiv">
                        <Button
                            classes=" fs-6 fw-bold rounded-4 scale-in-center-slow"
                            text={<div className='d-flex align-items-center justify-content-center'>
                                {STRING.SaveButton}{isSubmitted ? <span className='ms-2 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                            </div>}
                            type={INPUT_TYPES.SUBMIT}
                        />
                    </div>

                </div>
                <Row>
                    <Col sm={12} md={6}>
                        <Controller
                            control={control}
                            name="current_password"
                            rules={{
                                required: STRING.CurrentPasswordRequired,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputGroup>
                                    <Input
                                        classes="mt-2 mb-1"
                                        placeholder={STRING.CurrentPassword}
                                        type={INPUT_TYPES.PASSWORD}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </InputGroup>

                            )}
                        />
                        {errors?.current_password ? <p className='error_message fs-7 ms-2 mt-1 mb-2'>{errors.current_password?.message}</p> : ""}
                    </Col>
                    <Col sm={12} md={6}>
                        <Controller
                            control={control}
                            name="new_password"
                            rules={{
                                required: STRING.NewPasswordRequired,
                                minLength: {
                                    value: 6,
                                    message: STRING.PasswordSixCharacterRequired,
                                },
                                pattern: {
                                    value: INPUT_PATTERNS.PASSWORD_PATTERNS,
                                    message: STRING.PasswordPatternRequired,
                                },
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputGroup>
                                    <Input
                                        classes="mt-2 mb-1"
                                        placeholder={STRING.NewPassword}
                                        type={INPUT_TYPES.PASSWORD}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </InputGroup>

                            )}
                        />
                        {errors?.new_password ? <p className='error_message fs-7 ms-2 mt-1 mb-2'>{errors.new_password?.message}</p> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Controller
                            control={control}
                            name="confirm_password"
                            rules={{
                                required: STRING.Confirm2PasswordRequired,
                                validate: value => (value !== watchFields) ? "Confirm password should be same as new password": clearErrors('confirm_password'),

                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputGroup>
                                    <Input
                                        classes="mt-2 mb-1"
                                        placeholder={STRING.ConfirmNewPassword}
                                        type={INPUT_TYPES.PASSWORD}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </InputGroup>
                            )}
                        />
                        {errors?.confirm_password ? <p className='error_message fs-7 ms-2 mt-1 mb-2'>{errors.confirm_password?.message}</p> : ""}

                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ChangePasswordForm
