import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSocket } from "../../../Context/SocketContext";
import { useSelector } from "react-redux";
import _ from "lodash";


export const ChatContext = createContext();

export function useChatContext() {
    return useContext(ChatContext)
}


export default function ChatContextProvider({ children }) {
    const socket = useSocket();
    const { isSocketConnected, user } = useSelector(state => state.authInfo)

    const INITIAL_CHAT_THREAD = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])

    const INITIAL_GROUP_THREAD = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])



    const [chatRoom, setChatRoom] = useState({})
    const [isGroup, setIsGroup] = useState(false)

    const [chatThread, setChatThread] = useState(INITIAL_CHAT_THREAD)
    const [groupThread, setGroupThread] = useState(INITIAL_GROUP_THREAD)


    useEffect(() => {
        if (isSocketConnected) {
            socket.on('getChatThreads_', (data) => {
                setChatThread(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: data?.data || []
                    }
                })
            })

            socket.on('getGroupThreads_', (data) => {
                setGroupThread(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: data?.data || []
                    }
                })
            })

            socket.on('newGroup_', (data) => {
                handleAddGroup(data?.data)
                socket.emit('_joinRoom', data?.data?.chat_room_slug)
            })

        }


        return () => {
            if (isSocketConnected) {
                socket.dispose('getChatThreads_')
                socket.dispose('getGroupThreads_')
                socket.dispose('newGroup_')
            }
        }

    }, [isSocketConnected])

    useEffect(() => {
        if (!isSocketConnected) return


        socket.on('receivedMessage_', (res) => {
            const data = res?.data || {};
            setChatThread(prev => {
                return {
                    ...prev,
                    data: prev?.data.map(item => {
                        if (item.chat_room_slug !== data?.chat_room_slug) return item
                        return {
                            ...item,
                            message: {
                                ...item?.message,
                                message_type: data?.message_type,
                                message: data?.message,
                                createdAt: data?.message_timestamp,
                            },
                            unread_message_count: (chatRoom?.slug === data?.chat_room_slug) ? item?.unread_message_count : (item?.unread_message_count + 1)
                        }
                    })
                }
            })

            setGroupThread(prev => {
                return {
                    ...prev,
                    data: prev?.data.map(item => {
                        if (item.chat_room_slug !== data?.chat_room_slug) return item
                        return {
                            ...item,
                            message: {
                                ...item?.message,
                                message_type: data?.message_type,
                                message: data?.message,
                                createdAt: data?.message_timestamp,
                            },
                            unread_message_count: (chatRoom?.slug === data?.chat_room_slug) ? item?.unread_message_count : (item?.unread_message_count + 1)
                        }
                    })
                }
            })
        })

        return () => {
            if (isSocketConnected) {
                socket.dispose('receivedMessage_')
            }
        }

    }, [isSocketConnected, chatRoom])


    useEffect(() => {
        if (!isSocketConnected) return

        socket.emit('_getChatThreads')
        socket.emit('_getGroupThreads')

    }, [isSocketConnected])




    const handleTabChange = (isGroup) => {
        setIsGroup(isGroup)
        setChatRoom({})
    }

    const resetMessageCount = (chat_room_slug) => {
        setChatThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== chat_room_slug) return item
                    return {
                        ...item,
                        unread_message_count: 0
                    }
                })
            }
        })
        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== chat_room_slug) return item
                    return {
                        ...item,
                        unread_message_count: 0
                    }
                })
            }
        })
    }

    const updateLastMessagePayload = (data) => {
        setChatThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== data?.chat_room_slug) return item
                    return {
                        ...item,
                        message: {
                            ...item?.message,
                            message_type: data?.message_type,
                            message: data?.message,
                            createdAt: data?.message_timestamp,
                        },
                    }
                })
            }
        })

        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== data?.chat_room_slug) return item
                    return {
                        ...item,
                        message: {
                            ...item?.message,
                            message_type: data?.message_type,
                            message: data?.message,
                            createdAt: data?.message_timestamp,
                        },
                    }
                })
            }
        })
    }


    const handleAddGroup = (groupData) => {
        setGroupThread(prev => {
            return {
                ...prev,
                data: [
                    {
                        ...groupData,
                        is_admin: groupData.user_slug === user.slug
                    },
                    ...prev?.data,
                ]
            }
        })
    }

    return (
        <ChatContext.Provider value={{
            chatThread,
            groupThread,
            chatRoom,
            isGroup,
            setChatRoom,
            handleTabChange,
            resetMessageCount,
            updateLastMessagePayload,
            handleAddGroup
        }}>
            {children}
        </ChatContext.Provider>
    )

}