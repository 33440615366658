const ROUTES = {
    DASHBOARD: 'DASHBOARD',
    POST_VIEW: 'POST_VIEW',
    POST_VIEW_PREMIUM: 'POST_VIEW_PREMIUM',
    WORKOUT_VIEW: 'WORKOUT_VIEW',
    WORKOUT_VIEW_PREMIUM: 'WORKOUT_VIEW_PREMIUM',
    // TEMPLATE_VIEW: 'TEMPLATE_VIEW',
    // TEMPLATE_VIEW_PREMIUM: 'TEMPLATE_VIEW_PREMIUM',
}

export { ROUTES }