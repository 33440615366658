import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

// import styles from './styles/Inbox.module.scss';


import { Form, InputGroup, Spinner } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { ImAttachment } from "react-icons/im";
import { IoIosArrowBack } from "react-icons/io";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { TiCamera } from "react-icons/ti";
import { useSelector } from "react-redux";
import AddMemberInGroupModal from "../../Components/Modals/AddMemberInGroupModal";
import EditGroupModal from "../../Components/Modals/EditGroupModal";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { STRING } from "../../Constant/StringConstant";
import { useSocket } from "../../Context/SocketContext";
import { getMessageTime } from "../../Helper";
import { useChatContext } from "./Context/ChatContext";
import { MESSAGE_TYPE_ENUM } from "../../Constant/constants";

export default function Inbox({ isMobile = false }) {
  const socket = useSocket();
  const { isSocketConnected, user } = useSelector(state => state.authInfo)

  const { isGroup, chatRoom, setChatRoom, updateLastMessagePayload } = useChatContext()

  const INITIAL_CHAT_HISTORY = useMemo(() => {
    return {
      isError: false,
      isLoading: true,
      data: []
    }
  }, [])


  const messageRef = useRef();
  const popoverRef = useRef(null);
  const [page, setPage] = useState(1);
  const [chatHistory, setChatHistory] = useState(INITIAL_CHAT_HISTORY);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [editGroupModal, setEditGroupModal] = useState(false);



  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('loadChatHistory_', (data) => {
        setChatHistory(prev => {
          return {
            ...prev,
            isLoading: false,
            data: data?.data || []
          }
        })
      })

      socket.on('receivedMessage_', (data) => {
        const message = data?.data
        if (message?.chat_room_slug !== chatRoom.slug) return;
        if (message.user_slug !== user.slug) {
          setChatHistory(prev => {
            return {
              ...prev,
              data: [
                ...prev?.data,
                message
              ]
            }
          })
        }
        else {
          setChatHistory(prev => {
            return {
              ...prev,
              data: [
                ...prev?.data.filter(item => item.message_uuid !== data?.payload.message_uuid),
                message
              ]
            }
          })
        }
        updateLastMessagePayload(message)
        socket.emit('_resetMessageCount', { chat_room_slug: chatRoom.slug })
      })
    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('loadChatHistory_')
        socket.dispose('receivedMessage_')
      }
    }

  }, [isSocketConnected])


  useEffect(() => {
    if (!_.isEmpty(chatRoom)) {
      socket.emit("_loadChatHistory", { chat_room_slug: chatRoom.slug })
    }
  }, [page])

  useEffect(() => {
    scrollChatContainer()
  }, [chatHistory?.data.length])



  const handleBackButtonClick = () => {
    setChatRoom({});
  };

  const togglePopover = () => {
    if (!chatRoom.is_admin) return;
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverOpen(false);
    }
  };

  const handleLeaveGroup = () => {
  };

  const handleEditGroup = () => {
    setEditGroupModal(true);
  };
  const handleEditGroupModalClose = () => {
    setEditGroupModal(false);
  };

  const handleAddMemberInGroupModalOpen = () => {
    setAddMemberModal(true);
  };
  const handleAddMemberInGroupModalClose = () => {
    setAddMemberModal(false);
  };

  const scrollChatContainer = () => {
    const container = document.getElementById('chatContainer')
    container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
  }

  const handleSendMessage = () => {
    const message = messageRef.current.value;
    if (!message) return;

    const payload = {}
    payload.chat_room_slug = chatRoom.slug;
    payload.message_type = MESSAGE_TYPE_ENUM.TEXT;
    payload.message = message
    payload.message_uuid = uuidv4()
    setChatHistory(prev => {
      return {
        ...prev,
        data: [
          ...prev?.data,
          {
            ...payload,
            user_slug: user.slug,
            user_name: user.name,
            user_image: user.image_url,
            message_timestamp: new Date()
          }
        ]
      }
    })
    socket.emit('_sendMessage', payload);
    messageRef.current.value = ''
  }

  return (
    <>
      {
        addMemberModal ? <AddMemberInGroupModal chat_room_slug={chatRoom?.slug} handleModalClose={handleAddMemberInGroupModalClose} /> : ''
      }
      {
        editGroupModal ? <EditGroupModal handleModalClose={handleEditGroupModalClose} /> : ''
      }


      <div className="d-flex flex-row justify-content-end">
        <p className="fw-bold">{STRING.MarkAllAsRead} </p>
      </div>
      <div className="Inbox focus-in-expand">
        <div className="titleContainer">
          <div className="userContainer">
            <div className="leftSide">
              {isMobile ? (
                <span className="backButton" onClick={handleBackButtonClick}>
                  <IoIosArrowBack />
                </span>
              ) : (
                ""
              )}
              <div className="imageDiv">
                <img
                  className="image"
                  src={chatRoom.image_url}
                  alt="User Profile"
                />
                <span className="onlineIndicator"></span>
              </div>

              <div className="nameContainer">
                <p className="lc-1 text-white fs-4 mb-0">{chatRoom.room_name}</p>
                <p className="onlineText">Online</p>
              </div>
            </div>

            {(isGroup && chatRoom.is_admin) ? (
              <div className="rightSide" onClick={togglePopover}>
                <IoEllipsisHorizontalSharp color="#94A3B8" size={20} />
                {isPopoverOpen && (
                  <div
                    ref={popoverRef}
                    className={`d-flex flex-column popover ${isPopoverOpen ? `show slide-in-right-fast` : ""
                      }`}
                  >

                    <p className="m-0 py-2" onClick={handleAddMemberInGroupModalOpen}>
                      {STRING.AddMembers}
                    </p>

                    <p className="m-0 py-2" onClick={handleEditGroup}>
                      {STRING.EditSettings}
                    </p>

                    <p className="m-0 py-2" onClick={handleLeaveGroup}>
                      {STRING.LeaveGroup}
                    </p>

                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="chatContainer" id="chatContainer">
          {chatHistory?.isLoading ? <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white"><Spinner /></div> :
            _.isEmpty(chatHistory?.data) ? <div className="w-100 h-100 d-flex align-items-center justify-content-center"><p className="m-0 text-white fs-5">No chat messages</p></div> :
              (chatHistory?.data ?? []).map((item, index) => {
                const isMe = item.user_slug === user.slug;
                return (
                  <div className={`chat ${isMe ? "me" : ""}`} key={item.slug || item.message_uuid}>
                    <span className="info">{getMessageTime(item.message_timestamp)}</span>
                    <p className={`message ${isMe ? "me" : ""}`}>
                      {item.message}
                    </p>
                    {isMe ? <span className="info">Seen</span> : ""}
                  </div>
                );
              })
          }
        </div>

        <div className="messageContainer">
          <InputGroup>
            <Form.Control
              size="lg"
              className={` mt-1 mb-2  messageBar`}
              placeholder="Type a message"
              type={INPUT_TYPES.TEXT}
              ref={messageRef}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  handleSendMessage();
                  e.stopPropagation()
                }
              }}
            // onChange={onChange}
            />
            <div className="input-before-icon">
              <HiOutlineEmojiHappy size={20} />
            </div>
            <div className="input-after-icon">
              <ImAttachment size={20} />
              <TiCamera size={20} />
            </div>
          </InputGroup>

          <button className={`sendButton`} onClick={handleSendMessage}>
            <FiSend color="white" />
          </button>
        </div>
      </div>
    </>
  );
}
