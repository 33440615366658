import React, { useEffect, useMemo, useRef, useState } from "react";
import { STRING } from "../../../Constant/StringConstant";
import UserAvatarWithNameAndTag from "../../../Components/CustomComponents/UserAvatarWithNameAndTag";
import { IMAGES } from "../../../Constant/ImageConstant";
import { HiDotsHorizontal } from "react-icons/hi";
import { InputGroup } from "react-bootstrap";
import { Input } from "../../../Components/CustomComponents";
import { INPUT_TYPES } from "../../../Constant/InputTypesAndPatternsConstant";
import { BsEmojiSmile } from "react-icons/bs";
import { BsBookmark } from "react-icons/bs";
import { FaHeart } from "react-icons/fa6";
import { FaRegCommentDots } from "react-icons/fa";
import { LuShare2 } from "react-icons/lu";
import { BsBookmarkFill } from "react-icons/bs";
import AddModal from "../../../Components/Modals/AddModal";
import ShareCommunityPostModal from "../../../Components/Modals/ShareCommunityPostModal";
import { useNavigate } from "react-router-dom";
import { WEB_ROUTES } from "../../../Constant/constants";
import { baseRoute, getTime } from "../../../Helper";
import { useDispatch, useSelector } from "react-redux";

import HorizontalImageWithGradient from "./HorizontalImageWithGradient";
import { useLikePostMutation, useRemovePostLikeMutation, useSavePostMutation, useSendPostCommentMutation, useUnsavePostMutation } from "../../../Data/services/CommunityApi";
import {
  likePost as likePostSliceAction, removePostLike as removePostLikeSliceAction,
  savePost as savePostSliceAction,
  unsavePost as unsavePostSliceAction
} from "../../../Data/Features/communitySlice";
import PostComment from "./PostComment";
import ReportPostModal from "../../../Components/Modals/ReportPostModal";


const CommunityFollowingPostWithComment = ({ communityPost }) => {
  const user = useSelector(state => state.authInfo.user)
  const slug = useMemo(() => {
    return !!communityPost.is_shared ? communityPost.post_slug : communityPost.slug
  }, [])
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [likePost] = useLikePostMutation();
  const [removePostLike] = useRemovePostLikeMutation();
  const [sendComment] = useSendPostCommentMutation();
  const [savePost] = useSavePostMutation();
  const [unsavePost] = useUnsavePostMutation();

  const [isLikedSubmitted, setLikeSubmitted] = useState(false)
  const [isSaveSubmitted, setSavesubmitted] = useState(false)
  const [showComments, setShowComments] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isReportModal, setIsReportModal] = useState(false);


  const handleShowShareModal = () => {
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const popoverRef = useRef(null);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGotoOtherProfile = (user_slug, user_name, user_image) => {
    if (user.slug === user_slug) {
      navigate(baseRoute(WEB_ROUTES.PROFILE))
    }
    else {
      navigate(baseRoute(WEB_ROUTES.OTHER_PROFILE) + user_slug,
        {
          state: {
            name: user_name,
            image_url: user_image
          }
        }
      );
    }
  }

  const handleLikePost = async () => {
    if (isLikedSubmitted) return;
    try {
      setLikeSubmitted(true);
      dispatch(likePostSliceAction(slug))
      await likePost(slug).unwrap();
      setLikeSubmitted(false)
    } catch (e) {
      setLikeSubmitted(false)
      dispatch(removePostLikeSliceAction(slug))
    }
  }

  const handleRemoveLike = async () => {
    if (isLikedSubmitted) return;
    try {
      setLikeSubmitted(true);
      dispatch(removePostLikeSliceAction(slug))
      await removePostLike(slug).unwrap();
      setLikeSubmitted(false)
    } catch (e) {
      setLikeSubmitted(false)
      dispatch(likePostSliceAction(slug))
    }
  }

  const handleSavePost = async () => {
    if (isSaveSubmitted) return;
    try {
      setSavesubmitted(true);
      dispatch(savePostSliceAction(slug))
      await savePost(slug).unwrap();
      setSavesubmitted(false)
    } catch (e) {
      setSavesubmitted(false)
      dispatch(unsavePostSliceAction(slug))
    }
  }

  const handleUnsavePost = async () => {
    if (isSaveSubmitted) return;
    try {
      setSavesubmitted(true);
      dispatch(unsavePostSliceAction(slug))
      await unsavePost(slug).unwrap();
      setSavesubmitted(false)
    } catch (e) {
      setSavesubmitted(false)
      dispatch(savePostSliceAction(slug))
    }
  }

  const handleReportPostModalClose = () => {
    setIsReportModal(false)
  }

  const handleReportPostModalOpen = () => {
    setIsReportModal(true)
  }

  const handleSubmit = async () => {
    if (!commentText || isSubmitted) return;

    try {
      setIsSubmitted(true);
      const payload = {}
      payload.Post_slug = slug;
      payload.Comment = commentText
      const result = await sendComment(payload).unwrap();
      setCommentText('')
      setIsSubmitted(false)
    } catch (e) {
      setIsSubmitted(false)
    }
  }


  return (
    <div>
      {!isReportModal ? "" : <ReportPostModal post_slug={communityPost.slug} handleModalClose={handleReportPostModalClose} />}
      <div className="Community-Post-Following">
        <div className="d-flex flex-row justify-content-between w-100 mb-3">
          <UserAvatarWithNameAndTag
            userName={communityPost.user_name || ''}
            userTag={''}
            avatarImage={communityPost.user_image}
            time={getTime(communityPost.createdAt)}
            handleOnClick={() => handleGotoOtherProfile(communityPost.user_slug, communityPost.user_name, communityPost.user_image)}
          />
          {communityPost.is_mine ? "" :
            <div onClick={togglePopover} className="cursor-pointer">
              <HiDotsHorizontal size={20} />
            </div>
          }
        </div>
        <div className='d-flex flex-wrap'>
          <p className="m-0 mb-2 fs-6 text-granite">
            {communityPost.is_shared ? communityPost.shared_caption : communityPost.post_caption}
          </p>
        </div>

        {!communityPost.is_shared ? "" :
          <>
            <hr className="w-100"></hr>
            <div className="d-flex flex-row justify-content-between w-100 mb-3">
              <UserAvatarWithNameAndTag
                userName={communityPost.owner_name || ''}
                userTag={''}
                avatarImage={communityPost.owner_image}
                time={''}
                handleOnClick={() => handleGotoOtherProfile(communityPost.owner_slug, communityPost.owner_name, communityPost.owner_image)}
                size="sm"
              />
            </div>
            <div className='d-flex flex-wrap'>
              <p className="m-0 mb-2 fs-6 text-granite">
                {communityPost.post_caption}
              </p>
            </div>
          </>
        }

        {isPopoverOpen && (
          <div
            ref={popoverRef}
            className={`d-flex flex-column popover py-2 px-3 ${isPopoverOpen ? `show slide-in-right-fast` : ""
              }`}
          >
            <p className="m-0 py-2 cursor-pointer" onClick={handleReportPostModalOpen}> Report </p>
          </div>
        )}
        <div className="post-image mb-2">
          <HorizontalImageWithGradient
            image={communityPost.image_url}
            video={communityPost.video_url}
            thumbnail={communityPost.thumbnail_url}
            is_mine={communityPost.is_mine}
            is_paid={communityPost.is_paid}
          />
        </div>

        <hr className="w-100"></hr>

        {
          // <div className='d-flex flex-wrap' onClick={toggleComments}>
          // <p className='m-0 my-2 fw-bold primary-text'>{showComments ? STRING.CommunityPostCommentHide : STRING.CommunityPostCommentView}</p>
          // </div>
        }

        <div className="d-flex flex-row flex-wrap justify-content-between">
          <div className="left-side d-flex ">
            {!communityPost.is_liked ? (
              <p className="m-0 me-1 p-2 fs-8 cursor-pointer" onClick={handleLikePost}>
                <FaHeart className="me-1 fs-5" />
                {communityPost.likes} Likes
              </p>
            )
              : (
                <p className="m-0 me-1 p-2 fs-8 cursor-pointer" onClick={handleRemoveLike}>
                  <FaHeart className="me-1 fs-5" color="EB2560" />
                  {communityPost.likes} Likes
                </p>
              )

            }
            <p className="m-0 me-1 p-2 fs-8 cursor-pointer" onClick={toggleComments}>
              <FaRegCommentDots className="me-1 fs-5" /> {communityPost.comments} Comments
            </p>
            <p className="m-0 me-1 p-2 fs-8 cursor-pointer" onClick={handleShowShareModal}>
              <LuShare2 className="me-1 fs-5" /> Share
            </p>
          </div>
          {
            !communityPost.is_saved ?
              <div className="right-side cursor-pointer" onClick={handleSavePost}>
                <BsBookmark className="fs-5" />
              </div>
              :
              <div className="right-side cursor-pointer" onClick={handleUnsavePost}>
                <BsBookmarkFill className="fs-5" />
              </div>

          }
        </div>
        {!showComments ? "" :
          <div className={`commentsArea mb-3  show `}>
            <PostComment post_slug={slug} />
          </div>
        }


        <InputGroup className="mt-2">
          <Input
            classes="bg-white text-black py-1 d-flex align-items-center rounded pe-5"
            placeholder={STRING.CommentPlaceholder}
            type={INPUT_TYPES.TEXT}
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSubmit()
                e.preventDefault()
              }

            }}
          // onBlur={onBlur}
          />
          {
            <div className="input-after-icon-sm text-granite translate-middle-y">
              <BsEmojiSmile />
            </div>
          }
        </InputGroup>
      </div>


      {showShareModal ? (
        <ShareCommunityPostModal data={communityPost} handleModalClose={handleCloseShareModal} />
      ) : (
        ""
      )}
    </div>
  );
};

export default CommunityFollowingPostWithComment;
