import { createSlice } from "@reduxjs/toolkit";
import { communityApi } from "../services/CommunityApi";
import _ from "lodash";

const initialState = {
    following: {
        currentPage: 0,
        data: [],
    },
    for_you: {
        currentPage: 0,
        data: [],
    },
    forum: {
        currentPage: 0,
        data: [],
    },
    comments: {},
    post_comments: {}
};

export const communitySlice = createSlice({
    name: "community",
    initialState,
    reducers: {
        resetFollowing: (state) => {
            return {
                ...state,
                following: {
                    currentPage: 0,
                    data: [],
                },
            }
        },
        likePost: (state, actions) => {
            const slug = actions.payload
            return {
                ...state,
                for_you: {
                    ...state.for_you,
                    data: state.for_you.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            likes: item.likes + 1,
                            is_liked: true
                        }
                    })
                },
                following: {
                    ...state.following,
                    data: state.following.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            likes: item.likes + 1,
                            is_liked: true
                        }
                    })
                }
            }
        },
        removePostLike: (state, actions) => {
            const slug = actions.payload
            return {
                ...state,
                for_you: {
                    ...state.for_you,
                    data: state.for_you.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            likes: item.likes - 1,
                            is_liked: false
                        }
                    })
                },
                following: {
                    ...state.following,
                    data: state.following.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            likes: item.likes - 1,
                            is_liked: false
                        }
                    })
                }
            }
        },
        savePost: (state, actions) => {
            const slug = actions.payload
            return {
                ...state,
                for_you: {
                    ...state.for_you,
                    data: state.for_you.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            is_saved: true
                        }
                    })
                },
                following: {
                    ...state.following,
                    data: state.following.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            is_saved: true
                        }
                    })
                }
            }
        },
        unsavePost: (state, actions) => {
            const slug = actions.payload
            return {
                ...state,
                for_you: {
                    ...state.for_you,
                    data: state.for_you.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            is_saved: false
                        }
                    })
                },
                following: {
                    ...state.following,
                    data: state.following.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== slug) return item;
                        return {
                            ...item,
                            is_saved: false
                        }
                    })
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                communityApi.endpoints.getFollowing.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    if (params.page === 1) {
                        state.following.currentPage = 1;
                        state.following.data = payload.data;
                    }
                    else if (params.page > state.following.currentPage) {
                        state.following.currentPage = params.page;
                        state.following.data = [...state.following.data, ...payload.data]
                    }
                }
            )
            .addMatcher(communityApi.endpoints.getFollowing.matchRejected, (state) => { });
        builder
            .addMatcher(
                communityApi.endpoints.getForYou.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    if (params.page === 1) {
                        state.for_you.currentPage = 1;
                        state.for_you.data = payload.data;
                    }
                    else if (params.page > state.for_you.currentPage) {
                        state.for_you.currentPage = params.page;
                        state.for_you.data = [...state.for_you.data, ...payload.data]
                    }
                }
            )
            .addMatcher(communityApi.endpoints.getForYou.matchRejected, (state) => { });
        builder
            .addMatcher(
                communityApi.endpoints.getPostComment.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}
                    state.post_comments[params.slug] = payload.data
                }
            )
            .addMatcher(communityApi.endpoints.getPostComment.matchRejected, (state) => { });
        builder
            .addMatcher(
                communityApi.endpoints.sendPostComment.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}
                    state.post_comments[params.Post_slug] = [
                        ...(state.post_comments[params.Post_slug] || []),
                        payload.data
                    ]
                    state.for_you.data = state.for_you.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== params.Post_slug) return item;
                        return {
                            ...item,
                            comments: item.comments + 1,
                        }
                    })
                    state.following.data = state.following.data.map(item => {
                        const post_slug = item.is_shared ? item.post_slug : item.slug
                        if (post_slug !== params.Post_slug) return item;
                        return {
                            ...item,
                            comments: item.comments + 1,
                        }
                    })

                }
            )
            .addMatcher(communityApi.endpoints.sendPostComment.matchRejected, (state) => { });
        builder
            .addMatcher(
                communityApi.endpoints.getForum.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}

                    if (params.page === 1) {
                        state.forum.currentPage = 1;
                        state.forum.data = payload.data;
                    }
                    else if (params.page > state.forum.currentPage) {
                        state.forum.currentPage = params.page;
                        state.forum.data = [...state.forum.data, ...payload.data]
                    }
                }
            )
            .addMatcher(communityApi.endpoints.getForum.matchRejected, (state) => { });
        builder
            .addMatcher(
                communityApi.endpoints.getForumComment.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}
                    state.comments[params.slug] = payload.data
                }
            )
            .addMatcher(communityApi.endpoints.getForumComment.matchRejected, (state) => { });
        builder
            .addMatcher(
                communityApi.endpoints.sendForumComment.matchFulfilled,
                (state, { payload, meta }) => {
                    const params = meta.arg.originalArgs || {}
                    state.comments[params.Post_slug] = [
                        ...(state.comments[params.Post_slug] || []),
                        payload.data
                    ]
                }
            )
            .addMatcher(communityApi.endpoints.sendForumComment.matchRejected, (state) => { });
    },
});

export const { likePost, removePostLike, savePost, unsavePost, resetFollowing } = communitySlice.actions;

export default communitySlice.reducer;
