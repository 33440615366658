//MARK: INPUT TYPES
export const INPUT_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    TEXTAREA: 'textarea',
    EMAIL: 'email',
    PASSWORD: 'password',
    FULL_NAME: 'full_name',
    TIME: 'time',
    SUBMIT: 'submit',
}

//MARK: INPUT PATTERNS
export const INPUT_PATTERNS = {
    EMAIL_PATTERNS: /\S+@\S+\.\S+/,
    PASSWORD_PATTERNS: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
}

export const INVALID_NUMBER_INPUT_TYPE = [
    "-",
    "+",
    "e",
    "E",
    "."
]

export const INVALID_FLOAT_INPUT_TYPE = [
    "-",
    "+",
    "e",
    "E",
]