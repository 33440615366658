import React from 'react'

const Digit = ({ value, title }) => {
    const leftDigit = value >= 10 ? value.toString()[0] : '0';
    const rightDigit = value >= 10 ? value.toString()[1] : value.toString();

    return (
        <div className='main-digit-container scale-up-center-slow'>
            <div className='digit-container'>
                <div className='single-digit puff-in-hor fw-bold'> {leftDigit}  </div>
                <div className='single-digit puff-in-hor fw-bold'> {rightDigit}  </div>
            </div>
            <div className='title'> {title} </div>

        </div>
    )
}

export default Digit
