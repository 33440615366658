import React, { useState } from "react";
import ModalContainer from "../ModalContainer";
import { FaCircleXmark } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";
import { STRING } from "../../Constant/StringConstant";
import { Button, Checkbox, Input } from "../CustomComponents";
import { InputGroup } from "react-bootstrap";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
// import cssStyles from './styles/notification.module.css';
import { message, Upload } from "antd";
import { FaPlusCircle } from "react-icons/fa";
import _ from "lodash";
import Toast from "../../Hooks/useToast";
import { IMAGES } from "../../Constant/ImageConstant";

const AfterPurchasePremiumModal = ({ handleModalClose }) => {
  const [modalClass, setModalClass] = useState(
    "afterPurchasePremiumModalContainer scale-in-hor-center"
  );

  const handleModalCloseWithAnimation = () => {
    setModalClass("afterPurchasePremiumModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            {/* <p className='fs-4 fw-medium m-0'>
                            {STRING.CreateCommunityForm}
                        </p> */}

            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>
          </div>


          <p className='fs-6 text-granite m-0'>
            {STRING.successfullyPurchased}
          </p>

          <p className='fs-2 fw-bold m-0'>
            {STRING.premiumWorkout}
          </p>

          <div className="d-flex justify-content-center mb-4">
            <img
              src={IMAGES.WORKOUT_IMAGE}
              alt="Premium Workout"
              // style={{ width: "50%" }}
              className="image scale-in-center"
            />
          </div>




          <p className='fs-5 fw-medium m-0'>
            {STRING.WorkoutName}
          </p>

          <br></br>
          <br></br>

          <Button
            classes="scale-in-center-slow rounded-4 fs-5 mt-1"
            text={STRING.ViewVideo}
            type={INPUT_TYPES.SUBMIT}
          // onClick={handleSubmit}
          />
        </div>
      </ModalContainer>
    </div>
  );
};

export default AfterPurchasePremiumModal;
