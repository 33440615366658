import React, { useState } from 'react';
import { ROUTES } from './Route';
import Progress from './Progress';

export default function PROGRESS({ currentPage }) {
    const [page, setPage] = useState(ROUTES.PROGRESS);

    switch (page) {
        case ROUTES.PROGRESS:
            return <Progress setPage={setPage} />
        default:
            return <Progress setPage={setPage} />
    }


}

