import React from "react";
import _ from "lodash";

import Inbox from "./Inbox";
import ChatThreads from "./ChatThreads";
import { useChatContext } from "./Context/ChatContext";

export default function MobileLayout() {
    const { chatRoom } = useChatContext()

    return (
        <div className="Layout">
            {!_.isEmpty(chatRoom) ? <Inbox isMobile={true} /> : <ChatThreads />}
        </div>
    )
}