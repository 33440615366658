import React from "react";
import { SVG } from "../../Constant/SvgConstant";

const PostImageContainer = ({ image = '', thumbnail = "", is_paid = false, is_purchased = false }) => {
  return (
    <div className="imageWrapper overflow-hidden">
      {thumbnail !== "" ? (
        <div className="w-100 h-100 thumbnail-container">
          <img src={thumbnail} alt="" className={`image ${(is_paid && !is_purchased) ? 'blur' : ""}`} />
          <span >
            <img src={SVG.PLAY_ICON} alt="Play Sign" className="play-icon" />
          </span>
        </div>

      ) : (
        <>
          <img src={image} alt="" className={`image ${(is_paid && !is_purchased) ? 'blur' : ""}`} />
        </>
      )
      }
    </div >
  );
};

export default PostImageContainer;
