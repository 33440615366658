import React from 'react'
import Digit from './Digit'
import { STRING } from '../../../Constant/StringConstant'
import { Col, Row } from 'react-bootstrap'

const CustomTimer = ({ seconds, minutes, hours, days }) => {
    return (
        <>
            <Row className='my-3'>
                <Col>
                    {days !== undefined ? <Digit value={days} title={STRING.Days} /> : null}
                </Col>
            </Row>
            {
                //     days !== undefined ? (
                //     <div className='separatorContainer'> <div className='separator' > : </div>  </div>
                // ) : null
            }
            <div className='timerContainer'>

                <Digit value={hours} title={STRING.Hours} />
                <div className='separatorContainer fw-bold'> <div className='separator' > : </div>  </div>
                <Digit value={minutes} title={STRING.Minutes} addSeparator />
                <div className='separatorContainer fw-bold'> <div className='separator' > : </div>  </div>
                <Digit value={seconds} title={STRING.Seconds} />

            </div>
        </>
    )
}

export default CustomTimer


