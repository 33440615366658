import React from 'react'

const Chips = ({ text, index, handleRemoveChip = ()=>{} }) => {
    return (
        <div className="chip" key={index}>
            {text}
            <span className="closeChip" onClick={() => { handleRemoveChip(index) }}>&times;</span>
        </div>
    )
}

export default Chips
