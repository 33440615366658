import { Nav } from "react-bootstrap";
import styles from "../styles/index.module.css";



const CustomTabs = ({ tableView, setTableView, optionList }) => {
  return (
    <Nav>
      {optionList.map((v) => (
        <Nav.Item key={v}>
          <Nav.Link
            onClick={() => setTableView(v)}
            className={v === tableView ? styles.active_tab : styles.tabs_items}
          >
            {v}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default CustomTabs;
