import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FaRegEdit } from "react-icons/fa";
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, SubTitle } from '../../../Components/CustomComponents';
import { IMAGES } from '../../../Constant/ImageConstant';
import { INPUT_TYPES } from '../../../Constant/InputTypesAndPatternsConstant';
import { STRING } from '../../../Constant/StringConstant';
import { useGetSportsLookupQuery } from '../../../Data/services/lookupApi';
import { IMAGE_TYPES } from '../../../Constant/constants';
import { useUpdateProfileMutation } from '../../../Data/services/userAuthApi';
import { ErrorToast } from '../../../Hooks/useToast';
import { setUser } from '../../../Data/Features/authSlice';

const ProfileForm = ({ }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.authInfo.user);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [editable, setEditable] = useState(false);
    const [image_url, setImageUrl] = useState(user.image_url);

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: user.name,
            gender: user.gender,
            sports_type: _.isArray(user.sports_type) ? user.sports_type.map(item => ({ label: item, value: item })) : []
        }
    });

    const [updateProfile] = useUpdateProfileMutation()
    const fetchLookups = useGetSportsLookupQuery({
        refetchOnMountOrArgChange: true,
    });


    const look_data = useSelector((state) => state.lookups.sportsType);

    const loading = [
        { label: "Loading...", value: "Loading...", disabled: true },
    ];

    const handleImageChange = (e) => {
        if (isSubmitted) return;
        setImageUrl(e.target.files[0])
    }

    const handleChangeProfile = async (data) => {
        if (isSubmitted) return

        try {
            setIsSubmitted(true)
            const formData = new FormData();
            if (_.isObject(image_url)) {
                formData.append('image_url', image_url)
            }

            formData.append('name', data?.name)
            formData.append('gender', data?.gender || '')

            if (_.isEmpty(data?.sports_type)) {
                formData.append('sports_type[]', '')
            } else {
                for (let i = 0; i < data?.sports_type.length; i++) {
                    formData.append(`sports_type[${i}]`, data?.sports_type[i].value)
                }
            }
            const response = await updateProfile(formData).unwrap();
            dispatch(setUser(response.data))
            setIsSubmitted(false)
        }
        catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            else {
                ErrorToast("An error occurred during update profile.");
            }
            setIsSubmitted(false)
        }
    }


    return (
        <div>
            <Form
                onSubmit={handleSubmit(handleChangeProfile)}
                className='mb-5'>
                <div className='d-flex flex-row justify-content-between align-items-start flex-wrap'>

                    <div className='profileImageDiv'>
                        <input type="file" id="actual-btn" hidden className={'fileChooseInput'} onChange={handleImageChange} multiple={false} accept={IMAGE_TYPES} />
                        <label htmlFor='actual-btn' className={'label'}>
                            {_.isObject(image_url) ?
                                <img
                                    src={URL.createObjectURL(image_url)}
                                    alt='User Profile'
                                    className={'userImage'}
                                /> :
                                <img src={image_url} alt="" />
                            }
                        </label>
                        <div className='editBtn'>
                            <FaRegEdit color='black' size={24} />
                        </div>
                    </div>

                    <div className="buttonDiv">
                        <Button
                            classes="px-3 text-nowrap fs-6 fw-bold rounded-4 scale-in-center-slow"
                            text={<div className='d-flex align-items-center justify-content-center'>
                                {STRING.SaveChangesButton}{isSubmitted ? <span className='ms-2 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                            </div>}
                            type={INPUT_TYPES.SUBMIT}
                        />
                    </div>

                </div>

                <div className='d-flex flex-row justify-content-between align-items-start flex-wrap accountsDetails'>
                    <SubTitle text={STRING.AccountDetails} />
                    <p className='edit' onClick={() => { setEditable(!editable) }}>{STRING.Edit}</p>
                </div>

                <Row className=''>
                    <Col sm={12} md={6}>
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: STRING.FullNameRequired
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputGroup>
                                    <Input
                                        classes={`mt-2 ${errors?.name ? 'mb-0' : 'mb-2'}`}
                                        placeholder={STRING.FullName}
                                        type={INPUT_TYPES.TEXT}
                                        disabled={editable}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </InputGroup>
                            )} />
                        {errors?.name ? <p className='error_message fs-7 ms-2 mt-1 mb-2'>{errors.name?.message}</p> : ""}
                    </Col>
                    <Col sm={12} md={6}>
                        <Controller
                            control={control}
                            name="email"
                            // rules={{
                            //     required: STRING.EmailRequired,
                            //     pattern: {
                            //         value: INPUT_PATTERNS.EMAIL_PATTERNS,
                            //         message: STRING.ValidEmailRequired,
                            //     },
                            // }}
                            render={({ field: { onBlur, value, ref } }) => (
                                <InputGroup>
                                    <Input
                                        classes="mt-2 mb-2"
                                        placeholder={STRING.EmailAddress}
                                        type={INPUT_TYPES.EMAIL}
                                        disabled={true}
                                        value={user.email}
                                        onBlur={onBlur}
                                    />
                                </InputGroup>
                            )} />
                    </Col>
                </Row>

                <Row className=''>
                    <Col sm={12} md={6}>
                        <Controller
                            control={control}
                            name="gender"
                            // rules={{
                            //     required: STRING.EmailRequired,
                            //     pattern: {
                            //         value: INPUT_PATTERNS.EMAIL_PATTERNS,
                            //         message: STRING.ValidEmailRequired,
                            //     },
                            // }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputGroup>
                                    <Input
                                        classes="mt-2 mb-2"
                                        placeholder={STRING.Gender}
                                        type={INPUT_TYPES.TEXT}
                                        disabled={editable}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </InputGroup>
                            )} />
                    </Col>
                    <Col sm={12} md={6}>
                        <Controller
                            control={control}
                            name={"sports_type"}
                            rules={{
                                required: "Please select sports type.",
                            }}
                            defaultValue={[]}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return (
                                    <MultiSelect
                                        options={look_data ?? loading}
                                        value={value}
                                        onChange={onChange}
                                        labelledBy="Select Sports Type"
                                        hasSelectAll={false}
                                        disableSearch={true}
                                        className={"multi-select-input"}
                                    />
                                )
                            }}
                        />
                        {errors?.sports_type ? <p className='error_message fs-7 ms-2 mt-1 mb-2'>{errors.sports_type?.message}</p> : ""}

                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ProfileForm
