import React from 'react'
import UserAvatarWithNameAndTag from '../CustomComponents/UserAvatarWithNameAndTag'
import { STRING } from '../../Constant/StringConstant'
import { IMAGES } from '../../Constant/ImageConstant'
import { FaCircleCheck } from 'react-icons/fa6'

const MemberSelectionCard = ({ isSelected, onSelect, data = {} }) => {
  return (
    <div onClick={onSelect} className="d-flex flex-row justify-content-between align-items-center w-100 my-1">

      <UserAvatarWithNameAndTag
        userName={data.username}
        userTag={data.name}
        avatarImage={data.image_url}
      />

      <FaCircleCheck
        style={{
          fontSize: "15px",
          color: isSelected ? "#FBBE21" : "#26262E",
          // backgroundColor: "white",
          borderRadius: "100px",
        }}
      />

    </div>
  )
}

export default MemberSelectionCard