const moment = require('moment')
const baseRoute = (name = '') => {
    return `${process.env.REACT_APP_DOMAIN_DIR}/${name}`
}

const apiBaseUrl = () => {
    return process.env.REACT_APP_API_DOMAIN + 'api/user/'
}

const localStorageToken = () => {
    return process.env.REACT_APP_WEB_STORAGE_TOKEN;
}

const getTime = (timestamp) => {
    if (!moment(timestamp).isValid()) return "";
    return moment(timestamp).fromNow()
}

const getMessageTime = (timestamp) => {
    if (!moment(timestamp).isValid()) return "";
    return moment(timestamp).format('hh:mm A')
}

const formatDate = (date = '', format = 'DD MMM YYYY') => {
    if (!moment(date).isValid()) return "";
    return moment(date).format(format)
}

const getWeekOfMonth = (date = new Date()) => {
    return (Math.ceil(parseInt(moment(date).date()) / 7) || 1)
}

export { baseRoute, apiBaseUrl, localStorageToken, getTime, getMessageTime, formatDate, getWeekOfMonth } 