import React, { useState } from 'react';
import { ROUTES } from './Route';
import Accolades from './Accolades';

export default function ACCOLADES() {
    const [page, setPage] = useState(ROUTES.ACCOLADES);

    switch (page) {
        case ROUTES.PROGRESS:
            return <Accolades setPage={setPage} />
        default:
            return <Accolades setPage={setPage} />
    }


}

