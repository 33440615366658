import React, { useState } from "react";
import { STRING } from "../../../Constant/StringConstant";
import { IMAGES } from "../../../Constant/ImageConstant";
import UserAvatarWithNameAndTag from "../../../Components/CustomComponents/UserAvatarWithNameAndTag";
import { useGetForumCommentQuery, useSendForumCommentMutation } from "../../../Data/services/CommunityApi";
import { EmptyContainer, Loader } from "../../../Components/CustomComponents";
import _ from "lodash";
import { Input } from "antd";
import { INPUT_TYPES } from "../../../Constant/InputTypesAndPatternsConstant";
import { IoIosSend } from "react-icons/io";
import { ErrorToast } from "../../../Hooks/useToast";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getTime } from "../../../Helper";



export default function ForumComment({ forum_slug }) {
    const [commentText, setCommentText] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const { isFetching } = useGetForumCommentQuery({ slug: forum_slug })
    const data = useSelector(state => (state.community.comments[forum_slug] || []))
    const [sendComment] = useSendForumCommentMutation({ slug: forum_slug })
    const handleSubmit = async () => {
        if (!commentText || isSubmitted) return;

        try {
            setIsSubmitted(true);
            const payload = {}
            payload.Post_slug = forum_slug;
            payload.Comment = commentText
            const result = await sendComment(payload).unwrap();
            setCommentText('')
            setIsSubmitted(false)
        } catch (e) {
            setIsSubmitted(false)
        }
    }
    return (
        <div>
            <p className=' fw-bold'> Comments </p>
            {
                isFetching ? <Loader /> :
                    _.isEmpty(data) ? <EmptyContainer text="No comments found" classes="pt-3 pb-4 m-0" /> :
                        <div className="Comment-Container">
                            {
                                ((data ?? []))?.map((comment, index) => (
                                    <div
                                        key={data.slug + '_' + index}
                                    >
                                        {index > 0 ? <hr style={{ width: '100%' }}></hr> : ""}
                                        <div className="d-flex flex-row justify-content-between w-100 my-3">
                                            <UserAvatarWithNameAndTag
                                                userName={comment.user.name ?? STRING.CommunityPostUserName}
                                                userTag={STRING.CommunityPostUserTag}
                                                avatarImage={comment.user.image_url ?? IMAGES.PROFILE_IMAGE}
                                            />
                                            <p className='m-0 post-date text-granite'>{getTime(comment.created_at)}</p>
                                        </div>
                                        <p className='text-granite fs-7'>
                                            {comment.comment}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
            }
            <div className="d-flex flex-row align-items-center mt-2">
                <Input
                    classes="bg-white text-black"
                    placeholder={STRING.CommentPlaceholder}
                    type={INPUT_TYPES.TEXT}
                    value={commentText}
                    onChange={(e) => {
                        setCommentText(e.target.value);
                    }}
                    onPressEnter={handleSubmit}
                // onBlur={onBlur}
                />
                {isSubmitted ? <Spinner style={{ color: "#F68B28" }} className="d-flex ms-2" /> :
                    <span className="fs-3 ms-2 cursor-pointer d-flex" onClick={handleSubmit}>
                        <IoIosSend style={{ color: "#F68B28" }} />
                    </span>
                }
            </div>

        </div>
    )
}