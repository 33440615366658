import React, { useEffect, useState } from 'react'
import _ from "lodash"
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import SharedPostWithComment from './SharedPostWithComment';
import { useGetMySharedPostQuery } from '../../../Data/services/userApi';
import { EmptyContainer } from '../../../Components/CustomComponents';

const SharedPost = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const { data, isFetching } = useGetMySharedPostQuery({ page: currentPage, limit: 18, offset }, {
    refetchOnMountOrArgChange: true,
  });

  const listingData = useSelector(state => state.user.my_shared_post.data)


  // MARK: USE EFFECT
  useEffect(() => {
    if (isFetching) return;
    if (!data?.links?.total_records) return;

    if (currentPage === 1) {
      setTotal(data.links?.total_records)
    }
    else if (data.links?.total_records > total) {
      setTotal(data.links.total_records)
      setOffset(prev => prev + (data.links?.total_records - total))
    }
  }, [isFetching])

  useEffect(() => {
    if (inView && !isFetching) {
      setCurrentPage(prev => prev + 1);
    }
  }, [inView])

  return (
    <div className='pe-md-2'>
      {(_.isEmpty(listingData) && !isFetching) ? <EmptyContainer text="No shared post found" /> :

        (listingData ?? []).map((post, index) => {
          return <div
        ref={
          (listingData.length >= (total - offset)) ? null :
            (index === (listingData.length - 1)) ? ref : null}
      >
        <SharedPostWithComment
          key={index}
          post={post}
        />
      </div>
        })
      }
      {isFetching ? <div className="w-100 d-flex justify-content-center py-4 text-light"><Spinner color="white" /></div> : ""}
    </div >

  )
}

export default SharedPost
