/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { STRING } from '../../../Constant/StringConstant';


const CustomNavbar = ({ activeLink, handleChangeActive }) => {

    useEffect(() => {
        return () => {

        };
    }, [activeLink]);

    return (

        <ul className='d-flex flex-wrap w-100 slide-in-right-fast'>
            <li className='scale-in-center-slow'>
                <a
                    href="#"
                    className={activeLink === STRING.Following ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.Following)}
                >
                    {STRING.Following}
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"
                    className={activeLink === STRING.ForYou ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.ForYou)}
                >
                    {STRING.ForYou}
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"
                    className={activeLink === STRING.Forum ? `${`active`}` : ''}
                    onClick={() => handleChangeActive(STRING.Forum)}
                >
                    {STRING.Forum}
                </a>
            </li>
        </ul>
    )
}

export default CustomNavbar
