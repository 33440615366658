import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    text: '',
};

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setValue: (state, actions) => {
            return {
                ...state,
                text: actions.payload
            }
        },
        resetValue: () => {
            return initialState
        },
    },
});

export const { setValue, resetValue } = searchSlice.actions;

export default searchSlice.reducer;
