import React, { useEffect, useRef, useState } from "react";
import { Button, EmptyThreadContainer } from "../../Components/CustomComponents";
import CreateGroupModal from "../../Components/Modals/CreateGroupModal";
import { IMAGES } from "../../Constant/ImageConstant";
import { STRING } from "../../Constant/StringConstant";
import CustomNavbar from "./Components/CustomNavbar";
import GroupChatThread from "./Components/GroupChatThread";
import SingleChatThread from "./Components/SingleChatThread";
import { useChatContext } from "./Context/ChatContext";
import _ from "lodash";
import { getTime } from "../../Helper";
import { useSocket } from "../../Context/SocketContext";
import { useSelector } from "react-redux";
import { SuccessToast } from "../../Hooks/useToast";

export default function ChatThreads() {
  const socket = useSocket()
  const componentRef = useRef()
  const { isSocketConnected, user } = useSelector(state => state.authInfo)
  const { setChatRoom, isGroup, handleTabChange, chatThread, groupThread, resetMessageCount, handleAddGroup } = useChatContext()
  const [openCreateModal, setOpenCreateModal] = useState(false);


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('createGroup_', (data) => {
        SuccessToast("Group created successfully")
        componentRef.current.successCB()
        handleAddGroup(data?.data)
      })
    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('createGroup_')
      }
    }
  }, [isSocketConnected])

  const handleCreateModalOpen = () => {
    setOpenCreateModal(true);
  }

  const handleCreateModalClose = () => {
    setOpenCreateModal(false);
  }

  const handleChangeActive = (link) => {
    handleTabChange(link === STRING.Groups);
  };

  const handleThreadClick = (data) => {
    setChatRoom({
      slug: data.chat_room_slug,
      room_name: data.room_name || '',
      image_url: data.image_url
    });
    resetMessageCount(data?.chat_room_slug)
  };

  const handleThreadGroupClick = (data) => {
    setChatRoom({
      slug: data.chat_room_slug,
      room_name: data.room_name || '',
      image_url: data.image_url,
      members: data.members,
      is_admin: !!data.is_admin
    });
    resetMessageCount(data?.chat_room_slug)
  };


  return (
    <div className="ChatThreads focus-in-expand">
      {openCreateModal ? <CreateGroupModal handleModalClose={handleCreateModalClose} ref={componentRef} /> : ""}
      <div className="titleContainer">
        <p className="m-0 fs-4 text-white">{STRING.Messages}</p>
        <CustomNavbar
          activeLink={!isGroup ? STRING.Chat : STRING.Groups}
          handleChangeActive={handleChangeActive}
        />
      </div>
      <div className="threads">
        {
          !isGroup ?
            (chatThread?.isLoading || _.isEmpty(chatThread?.data)) ? <EmptyThreadContainer isLoading={chatThread?.isLoading} /> :
              (chatThread?.data ?? []).map((item) => {
                return <SingleChatThread
                  key={item.slug}
                  handleThreadClick={() => handleThreadClick(item)}
                  image={item.image_url}
                  name={item.room_name}
                  lastMsg={item?.message?.message || ''}
                  lastTime={getTime(item?.message?.createdAt)}
                  isOnline={false}
                  unReadMsg={!!item.unread_message_count || false}
                />
              }
              )
            :
            <>
              <div className="d-flex flex-row justify-content-end mb-2">
                <Button
                  classes=" fs-6 fw-bold scale-in-center"
                  style={{ width: "200px" }}
                  text={STRING.CreateGroupBtnTxt}
                  // type={INPUT_TYPES.SUBMIT}
                  onClick={handleCreateModalOpen}

                />
              </div>
              <div className="group-thread-wrapper h-100">
                {
                  (groupThread?.isLoading || _.isEmpty(groupThread?.data)) ?
                    <EmptyThreadContainer isLoading={groupThread?.isLoading} /> :
                    (groupThread?.data ?? []).map((item, index) => {
                      return <GroupChatThread
                        groupName={item.room_name}
                        groupImage={item.image_url}
                        groupMembers={item.members}
                        handleThreadClick={() => handleThreadGroupClick(item)}
                      />
                    })
                }
              </div>
            </>
        }
      </div>
    </div>
  );
}
