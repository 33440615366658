import { END_POINTS } from "../../Constant/EndPointsContants";
import { baseApi } from "./baseApi";

export const communityApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getFollowing: builder.query({
            query: ({ page = 1, offset = 0, limit = 18 }) => {
                let url = END_POINTS.Community.GET_FOLLOWING + `?page=${page}&offset=${offset}&limit=${limit}`
                return {
                    url: url,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: () => (['Community/Following']),
            keepUnusedDataFor: 0,
            refetchOnFocus: true,
        }),
        getForYou: builder.query({
            query: ({ page = 1, offset = 0, limit = 18 }) => {
                let url = END_POINTS.Community.GET_FORYOU + `?page=${page}&offset=${offset}&limit=${limit}`
                return {
                    url: url,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: () => (['Community/For-You']),
            keepUnusedDataFor: 0,
            refetchOnFocus: true,
        }),
        getPostComment: builder.query({
            query: ({ slug }) => {
                let url = END_POINTS.Community.GET_POST_COMMENT + slug
                return {
                    url: url,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: (_, __, { slug }) => (['Community/Post/' + slug + '/Comment']),
            keepUnusedDataFor: 0,
            refetchOnFocus: true,
        }),
        sendPostComment: builder.mutation({
            query: (data) => {
                let url = END_POINTS.Community.SEND_POST_COMMENT
                return {
                    url: url,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        sharePost: builder.mutation({
            query: ({ slug, data }) => {
                let url = END_POINTS.Community.SHARE_POST(slug)
                return {
                    url: url,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        getForum: builder.query({
            query: ({ page = 1, offset = 0, limit = 18 }) => {
                let url = END_POINTS.Community.GET_FORUM + `?page=${page}&offset=${offset}&limit=${limit}`
                return {
                    url: url,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: () => (['Community', 'Forum']),
            keepUnusedDataFor: 0,
            refetchOnFocus: true,
        }),
        getForumComment: builder.query({
            query: ({ slug }) => {
                let url = END_POINTS.Community.GET_FORUM_COMMENT + slug
                return {
                    url: url,
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            providesTags: (_, __, { slug }) => (['Community/Forum/' + slug + '/Comment']),
            keepUnusedDataFor: 0,
            refetchOnFocus: true,
        }),
        sendForumComment: builder.mutation({
            query: (data) => {
                let url = END_POINTS.Community.SEND_FORUM_COMMENT
                return {
                    url: url,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        shareForum: builder.mutation({
            query: ({ slug, data }) => {
                let url = END_POINTS.Community.SHARE_FORUM(slug)
                return {
                    url: url,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),

        likePost: builder.mutation({
            query: (slug) => {
                let url = END_POINTS.Community.LIKE_POST + slug
                return {
                    url: url,
                    method: "POST",
                    body: {},
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        removePostLike: builder.mutation({
            query: (slug) => {
                let url = END_POINTS.Community.REMOVE_POST_LIKE + slug
                return {
                    url: url,
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        savePost: builder.mutation({
            query: (slug) => {
                let url = END_POINTS.Community.SAVE_POST + slug
                return {
                    url: url,
                    method: "POST",
                    body: {},
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        unsavePost: builder.mutation({
            query: (slug) => {
                let url = END_POINTS.Community.UNSAVE_POST + slug
                return {
                    url: url,
                    method: "DELETE",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        reportPost: builder.mutation({
            query: (data) => {
                let url = END_POINTS.Community.REPORT_POST
                return {
                    url: url,
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),

    })
});

export const {
    useGetFollowingQuery,
    useGetForYouQuery,
    useGetPostCommentQuery,
    useSendPostCommentMutation,
    useSharePostMutation,
    useGetForumQuery,
    useGetForumCommentQuery,
    useSendForumCommentMutation,
    useShareForumMutation,
    useLikePostMutation,
    useRemovePostLikeMutation,
    useSavePostMutation,
    useUnsavePostMutation,
    useReportPostMutation
} = communityApi;
