import React from 'react'

const PaymentSvgIcon = ({ color = "white" }) => {
    return (
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8333 0H4.16667C3.062 0.00132321 2.00296 0.440735 1.22185 1.22185C0.440735 2.00296 0.00132321 3.062 0 4.16667L0 10.8333C0.00132321 11.938 0.440735 12.997 1.22185 13.7782C2.00296 14.5593 3.062 14.9987 4.16667 15H15.8333C16.938 14.9987 17.997 14.5593 18.7782 13.7782C19.5593 12.997 19.9987 11.938 20 10.8333V4.16667C19.9987 3.062 19.5593 2.00296 18.7782 1.22185C17.997 0.440735 16.938 0.00132321 15.8333 0ZM4.16667 1.66667H15.8333C16.4964 1.66667 17.1323 1.93006 17.6011 2.3989C18.0699 2.86774 18.3333 3.50363 18.3333 4.16667H1.66667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667ZM15.8333 13.3333H4.16667C3.50363 13.3333 2.86774 13.0699 2.3989 12.6011C1.93006 12.1323 1.66667 11.4964 1.66667 10.8333V5.83333H18.3333V10.8333C18.3333 11.4964 18.0699 12.1323 17.6011 12.6011C17.1323 13.0699 16.4964 13.3333 15.8333 13.3333Z" fill={color} />
        </svg>

    )
}

export default PaymentSvgIcon
