import React from 'react'
import GroupUsersAvatar from './GroupUsersAvatar'

const GroupChatThread = ({ handleThreadClick, groupImage, groupName, groupMembers }) => {
  return (
    <>
      <div >
        <div className="my-2 overflow-visible group-thread-card cursor-pointer" onClick={handleThreadClick} >
          <div className="group-thread">
            <div className="imageDiv">
              <img className="image" src={groupImage} alt="Group Avatar" />
              <p className="text-white fs-5 lc-1 m-0 my-2 pe-1 lc-1 text-center">{groupName}</p>
            </div>
            <div className="usersInfo">
              <GroupUsersAvatar groupMembers={groupMembers} />
            </div>
          </div>
          {/* <p className="fs-8 last-time">{lastTime}</p> */}
        </div>
      </div>
    </>
  )
}

export default GroupChatThread