/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { STRING } from '../../../Constant/StringConstant';
// import styles from './CustomNavbar.module.scss';

const CustomNavbar = ({ activeLink, handleChangeActive }) => {



    useEffect(() => {

        return () => {

        };
    }, [activeLink]);

    return (

        <ul className='d-flex flex-wrap justify-content-around w-100 slide-in-right-fast'>
            <li className='scale-in-center-slow'>
                <a
                    href="#"
                    className={activeLink === STRING.All ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.All)}
                >
                    All
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"

                    className={activeLink === STRING.Post ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.Post)}
                >
                    Post
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"

                    className={activeLink === STRING.Workout ? `${`active`}` : ''}
                    onClick={() => handleChangeActive(STRING.Workout)}
                >
                    Workout
                </a>
            </li>
            <li className='scale-in-center-slow'>
                <a href="#"

                    className={activeLink === STRING.Templates ? `${`active`}` : ''}
                    onClick={() => handleChangeActive(STRING.Templates)}
                >
                    Templates
                </a>
            </li >
        </ul >

    )
}

export default CustomNavbar
