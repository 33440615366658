import _ from "lodash";

const createOptionDTO = (option) => ({
  label: option,
  value: option,
});

export const receivedSportsTypeDTOs = (data) => {
  return _.map(data, createOptionDTO);
};

export const requestSportsTypeDTOs = (options) => {
  return _.map(options, "label");
};
