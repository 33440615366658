import React, { useMemo } from 'react'
import PremiumSign from './PremiumSign'
import FreeTag from './FreeTag'
import { ROUTES } from '../Route'
import { Routes } from 'react-router-dom'

const HorizontalCard = ({ data, setPage }) => {

    const isPremium = useMemo(() => !!data.is_paid, []);
    const isMine = useMemo(() => !!data.is_mine, []);

    const handleViewPage = () => {
        if (isPremium) {
            setPage({
                route: ROUTES.WORKOUT_VIEW_PREMIUM,
                data: data
            });

        } else {

            setPage({
                route: ROUTES.WORKOUT_VIEW,
                data: data
            });
        }


    }

    return (
        <>
            <div className='workoutContainer' onClick={handleViewPage} role="button">

                <div className='imageWrapper'>
                    <img src={data.image_url} alt="" className={`image`} />
                    <div className='gradientOverlay'></div>
                </div>

                {
                    isPremium ?
                        <PremiumSign />
                        : <FreeTag text={'Free'} />
                }

                {
                    // isTemplate ? <TemplateSign text={'Template 1'} /> : ''
                }

                <p className='title lc-1 m-0 fs-5'> {data.title} </p>
            </div>

        </>
    )
}

export default HorizontalCard
