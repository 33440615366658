/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { STRING } from '../../../Constant/StringConstant';
// import styles from './CustomNavbar.module.scss';

const CustomNavbar = ({ activeLink, handleChangeActive }) => {

    return (

        <ul className='d-flex justify-content-between slide-in-right-fast'>
            <li className='scale-in-center-slow '>
                <a
                    href="#"
                    className={(activeLink === STRING.Chat) ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.Chat)}
                >
                    {STRING.Chat}
                </a>
            </li>
            <li className='scale-in-center-slow ms-1'>
                <a href="#"

                    className={(activeLink === STRING.Groups) ? `active` : ''}
                    onClick={() => handleChangeActive(STRING.Groups)}
                >
                    {STRING.Groups}
                </a>
            </li>

        </ul >

    )
}

export default CustomNavbar
