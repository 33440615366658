import React from 'react';
import { ImArrowDown } from "react-icons/im";

const DownloadTag = ({ text, onClick = () => { }, isEdit = true }) => {
    return (
        <div className='downloadTag scale-in-center' role={'button'} onClick={onClick} >
            {isEdit ? <ImArrowDown /> : ""}
            <span className='mx-1'>{text}</span>
        </div>
    )
}

export default DownloadTag
