import React, { useState } from "react";
import { InputGroup, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { STRING } from "../../Constant/StringConstant";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
// import cssStyles from './styles/notification.module.css';
import { ErrorToast } from "../../Hooks/useToast";
import { useReportPostMutation } from "../../Data/services/CommunityApi";

const ReportPostModal = ({ post_slug = '', handleModalClose = () => { } }) => {
    const [modalClass, setModalClass] = useState(
        "ReportPostModal scale-in-hor-center"
    );

    const [caption, setCaption] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    // const [mediaImage, setMediaImage] = useState('');

    const [reportPost] = useReportPostMutation();
    const handleModalCloseWithAnimation = () => {
        setModalClass("ReportPostModal scale-out-horizontal");
        setTimeout(() => {
            handleModalClose();
        }, 500);
    };

    const handleSubmit = async () => {
        if (isSubmitted) return;
        if (!caption) return ErrorToast("Please enter reason before submitting post report.")
        console.log("Report submitted");

        try {
            setIsSubmitted(true)
            const payload = {};
            payload.post_slug = post_slug;
            payload.reason = caption || '';
            await reportPost(payload).unwrap();
            handleModalCloseWithAnimation();
        }
        catch (e) {
            if (e?.data?.code === 400) {
                ErrorToast(e.data?.message)
            }
            setIsSubmitted(false)
        }
    }
    return (
        <div>
            <ModalContainer handleClose={handleModalCloseWithAnimation}>
                <div className={modalClass}>
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <p className="fs-4 fw-medium m-0">{STRING.ReportPost}</p>

                        <div className={"closeBtn"}>
                            <FaCircleXmark
                                fontSize={40}
                                color={"#F68B28"}
                                onClick={handleModalCloseWithAnimation}
                            />
                        </div>
                    </div>
                    <br></br>
                    <InputGroup>
                        <Input
                            as="textarea"
                            placeholder={STRING.ReportCaptionPlaceholder}
                            type={INPUT_TYPES.TEXT}
                            value={caption}
                            rows={3}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                    </InputGroup>

                    <Button
                        classes="scale-in-center-slow fs-5 mt-1"
                        text={
                            <div className='d-flex align-items-center justify-content-center'>
                                {STRING.ReportPostSubmit}{isSubmitted ? <span className='ms-3 d-flex'><Spinner style={{ fontSize: "10px" }} size="sm" /></span> : ""}
                            </div>
                        }
                        type={INPUT_TYPES.SUBMIT}
                        onClick={handleSubmit}
                    />
                </div>
            </ModalContainer>
        </div>
    );
};

export default ReportPostModal;
