const ROUTES = {
  PROFILE: "PROFILE",
  POST: "POST",
  WORKOUT: "WORKOUT",
  PREMIUM_POST: "PREMIUM_POST",
  PREMIUM_WORKOUT: "PREMIUM_WORKOUT",
  TEMPLATE: "TEMPLATE",
  PREMIUM_TEMPLATE: "PREMIUM_TEMPLATE",
};

export { ROUTES };
