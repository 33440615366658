import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { BackButton, EmptyContainer, Loader } from "../../Components/CustomComponents";
import AfterPurchasePremiumModal from "../../Components/Modals/AfterPurchasePremiumModal";
import { useGetWorkoutDetailsQuery } from "../../Data/services/dashboardApi";
import ExerciseCard from "./Components/ExerciseCard";
import HorizontalImageWithGradient from "./Components/HorizontalImageWithGradient";

const WorkoutViewPremium = ({ data = {}, handleBackButtonClick }) => {
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const { data: details, isFetching } = useGetWorkoutDetailsQuery(data.slug);

  const workOutData = useMemo(() => {
    if (_.isEmpty(details) && _.isEmpty(data)) return {};
    return (
      {
        ...(data ? data : {}),
        ...(details?.data ? details.data : {})
      }
    )

  }, [details])



  const fireEventForParent = () => {
    const event = new Event('showAfterPurchasedModal');
    window.dispatchEvent(event);
  }

  const handlePremiumWorkoutModalOpen = () => {
    console.log(handlePremiumWorkoutModalOpen);
    setShowPremiumModal(true);
    localStorage.setItem('showAfterPurchasedModal', true);
    fireEventForParent();
  }

  const handlePremiumWorkoutModalClose = () => {
    console.log(handlePremiumWorkoutModalClose);
    setShowPremiumModal(false);
    localStorage.setItem('showAfterPurchasedModal', false);
    fireEventForParent();
  }

  return (
    <div className="WorkoutViewPremium focus-in-expand-slow">

      {showPremiumModal ? <AfterPurchasePremiumModal handleModalClose={handlePremiumWorkoutModalClose} /> : ""}


      <div className="headerContainer">
        <div className="d-flex flex-row justify-content-start align-items-center">
          <BackButton
            classes="me-3"
            isRelative={true}
            handleBackButton={handleBackButtonClick}
          />
        </div>
      </div>
      {_.isEmpty(workOutData) ? <Loader /> :
        <>
          <HorizontalImageWithGradient image={data.image_url} />
          <div className="mt-4 ">
            <div className="d-flex flex-row flex-wrap justify-content-between align-content-start tags">
              <div>
                <p className="m-0 fs-3 fw-bold text-white">
                  {workOutData.title}
                </p>
                {/* <div className="d-flex flex-row flex-wrap ">
                  <p className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3">
                    Dribbling
                  </p>
                  <p className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3">
                    Basketball Techniques
                  </p>
                </div> */}
              </div>

              {workOutData?.is_mine ? "" :
                isFetching ? < p
                  className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3 h-100"
                >
                  <Spinner size="sm" />
                </p> :

                  workOutData?.payment ?
                    <p
                      className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3 h-100"
                    >
                      Purchased
                    </p> :

                    <p
                      className="text-white fw-bold text-white py-2 px-4 bg-carrotOrange rounded-3 h-100 cursor-pointer m-0"
                      onClick={handlePremiumWorkoutModalOpen}
                    >
                      ${workOutData.price} | Purchase Workout
                    </p>
              }
            </div>
            <div className="w-100 d-flex flex-row flex-wrap justify-content-left">
              {(workOutData.tags ?? []).map((item, index) => {
                return <p
                  className="text-white fw-bold text-white py-2 px-4 me-2 bg-carrotOrange rounded-3"
                  key={index}
                >
                  {item}
                </p>
              }
              )}
            </div>

            <p className="m-0 text-white fw-bold fs-4 mb-1"> Workout Description </p>
            {!workOutData.description ?
              (isFetching ? <span className="text-light ps-1 "><Spinner size="sm" /></span> : "") :
              <p className="m-0 fs-6 text-granite">{workOutData.description}</p>
            }
          </div>
          <p className="m-0 mt-4 text-white fs-4">Exercises</p>

          {isFetching ? <Loader /> :
            (!workOutData.is_mine && !workOutData.payment) ? <EmptyContainer text="Purchase workout to view exercises" /> :
              _.isEmpty(workOutData?.exercise) ? <EmptyContainer text="No exercises in this workout." /> :
                <Row className={` mt-2 mb-2`} gutter={[0, 24]}>
                  {
                    (workOutData?.exercise ?? []).map((exercise, index) => {
                      return < Col
                        sm={12} md={6}
                        key={index}
                      >
                        <ExerciseCard
                          exerciseNumber={"Exercise " + (index + 1)}
                          heading={exercise.title}
                          text={exercise.details}
                        />
                      </Col>
                    }
                    )}
                </Row>
          }

        </>}
    </div >
  );
};

export default WorkoutViewPremium;
