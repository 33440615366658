import React from 'react'

const RoundedCheckBox = () => {
    const handleChange = (e) => {
        console.log(e.target.checked)
    }
    return (
        <div>
            <div class="checkbox-wrapper-22" >
                <label class="switch" for="checkbox">
                    <input type="checkbox" id="checkbox" onChange={handleChange} />
                    <div class="slider round"></div>
                </label>
            </div>
        </div>
    )
}

export default RoundedCheckBox
