import React, { useState } from "react";
import PostView from "../../Components/PostDetails/PostView";
import PostViewPremium from "../../Components/PostDetails/PostViewPremium";
import WorkoutView from "../../Components/PostDetails/WorkoutView";
import WorkoutViewPremium from "../../Components/PostDetails/WorkoutViewPremium";
import OtherProfile from "./OtherProfile";
import { ROUTES } from "./Route";

export default function PROFILE() {
  const [page, setPage] = useState({
    route: ROUTES.PROFILE,
    prev_route: "",
    data: {}
  });

  const handlePostBackButton = () => {
    setPage(prev => ({
      ...prev,
      route: ROUTES.PROFILE,
      data: {}
    })
    )
  }

  const handleWorkoutBackButton = () => {
    setPage(prev => ({
      ...prev,
      route: ROUTES.PROFILE,
      data: {}
    })
    )
  }

  switch (page.route) {
    case ROUTES.PROFILE:
      return <OtherProfile prev_route={page?.prev_route} setPage={setPage} />;
    case ROUTES.POST:
      return <PostView data={page.data} handleBackButtonClick={handlePostBackButton} />;
    case ROUTES.PREMIUM_POST:
      return <PostViewPremium data={page.data} handleBackButtonClick={handlePostBackButton} />;

    case ROUTES.WORKOUT:
      return <WorkoutView data={page.data} handleBackButtonClick={handleWorkoutBackButton} />;
    case ROUTES.PREMIUM_WORKOUT:
      return <WorkoutViewPremium data={page.data} handleBackButtonClick={handleWorkoutBackButton} />;

    default:
      return <OtherProfile setPage={setPage} />;
  }
}
