import React from 'react'

const PrivacySvgIcon = ({ color = "white" }) => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5729 7.02V5.83333C14.5729 4.28624 13.9583 2.80251 12.8644 1.70854C11.7704 0.614582 10.2867 0 8.73958 0C7.19249 0 5.70876 0.614582 4.61479 1.70854C3.52083 2.80251 2.90625 4.28624 2.90625 5.83333V7.02C2.16405 7.34392 1.53233 7.8771 1.08833 8.55434C0.644338 9.23158 0.407315 10.0235 0.40625 10.8333V15.8333C0.407573 16.938 0.846985 17.997 1.6281 18.7782C2.40921 19.5593 3.46825 19.9987 4.57292 20H12.9063C14.0109 19.9987 15.07 19.5593 15.8511 18.7782C16.6322 17.997 17.0716 16.938 17.0729 15.8333V10.8333C17.0719 10.0235 16.8348 9.23158 16.3908 8.55434C15.9468 7.8771 15.3151 7.34392 14.5729 7.02ZM4.57292 5.83333C4.57292 4.72826 5.0119 3.66846 5.79331 2.88705C6.57471 2.10565 7.63451 1.66667 8.73958 1.66667C9.84465 1.66667 10.9045 2.10565 11.6859 2.88705C12.4673 3.66846 12.9063 4.72826 12.9063 5.83333V6.66667H4.57292V5.83333ZM15.4063 15.8333C15.4063 16.4964 15.1429 17.1323 14.674 17.6011C14.2052 18.0699 13.5693 18.3333 12.9063 18.3333H4.57292C3.90988 18.3333 3.27399 18.0699 2.80515 17.6011C2.33631 17.1323 2.07292 16.4964 2.07292 15.8333V10.8333C2.07292 10.1703 2.33631 9.53441 2.80515 9.06557C3.27399 8.59672 3.90988 8.33333 4.57292 8.33333H12.9063C13.5693 8.33333 14.2052 8.59672 14.674 9.06557C15.1429 9.53441 15.4063 10.1703 15.4063 10.8333V15.8333Z" fill={color} />
        </svg>

    )
}

export default PrivacySvgIcon
