import React from 'react';

import styles from "./styles/AccountLayout.module.scss";

import { Col, Row } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";


import { IMAGES } from '../../Constant/ImageConstant';
import { ShadowContainer } from '../CustomComponents';

export default function AccountLayout({
    hasBackButton = false,
    handleBackButton = () => { },
    children,
    leftSideImage
}) {
    return (
        <div className={styles.AccountLayout}>
            <ShadowContainer />
            <div className={styles.sectionContainer}>

                <Row className={styles.row}>
                    <Col
                        lg={7}
                        className={`${styles.mediaContainer}`}
                    >

                        <img src={leftSideImage} alt='Login' className={`${styles.image} scale-in-hor-center`} />
                    </Col>
                    <Col
                        lg={5}
                        className={`${styles.formContainer} p-0`} >

                        <div className={styles.form} style={{ maxWidth: '420px' }}>
                            {hasBackButton ? <div className={styles.backButtonContainer} onClick={handleBackButton}>
                                <IoIosArrowBack />
                            </div> : ""
                            }
                            {children}
                        </div>

                        <div className={`w-100 ${styles.stickyImage} slide-in-bottom`} style={{}} >
                            <img
                                src={IMAGES.PEACOCK}
                                alt='Login'
                                width={'150px'}
                            />
                        </div>

                    </Col>

                </Row>
            </div>

        </div>
    )
}