import React, { useCallback, useMemo } from 'react';
import PremiumSign from './PremiumSign';
import PlaySign from './PlaySign';
import { SubTitle } from '../../../Components/CustomComponents';
import TemplateSign from './TemplateSign';
import { ROUTES } from '../Route';
import { POST_TYPE_ENUM } from '../../../Constant/constants';

const GridCard = ({ data = {}, setPage, openFreeTemplate, openPremiumTemplate }) => {
    const isPremium = useMemo(() => !!data?.is_paid, []);
    const isMine = useMemo(() => !!data?.is_mine, []);

    const handleViewPage = ({ isTemplate, isWorkout }) => {
        if (isTemplate && isPremium) {
            openPremiumTemplate(data);
        }

        if (isTemplate && !isPremium) {
            openFreeTemplate(data);
        }

        if (isPremium && isWorkout) {
            setPage({
                route: ROUTES.WORKOUT_VIEW_PREMIUM,
                data: data
            });
        }

        if (!isPremium && isWorkout) {
            setPage({
                route: ROUTES.WORKOUT_VIEW,
                data: data
            });
        }

        if (isPremium && !isWorkout && !isTemplate) {
            setPage({
                route: ROUTES.POST_VIEW_PREMIUM,
                data: data
            });
        }

        if (!isPremium && !isWorkout && !isTemplate) {
            setPage({
                route: ROUTES.POST_VIEW,
                data: data
            });
        }

    }

    switch (data?.type) {
        case POST_TYPE_ENUM.IMAGE:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={handleViewPage} role="button">
                    <img src={data.image_url} alt="" className={`image ${(!isMine && isPremium) ? `premiumImage` : ''}    `} />
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                </div >
                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.caption}
                />
            </>
        case POST_TYPE_ENUM.VIDEO:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={handleViewPage} role="button">
                    <img src={data.thumbnail_url} alt="" className={`image`} />
                    <PlaySign />
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                </div>

                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.caption}
                />
            </>
        case POST_TYPE_ENUM.WORKOUT:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={() => handleViewPage({ isWorkout: true })} role="button">
                    <img src={data.image_url} alt="" className={`image `} />
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                </div>

                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.title}
                />
            </>
        case POST_TYPE_ENUM.TEMPLATE:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={() => handleViewPage({ isTemplate: true })} role="button">
                    <div className='templateBox'></div>
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                    <TemplateSign text={'Template 1'} />
                </div>
                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.name}
                />
            </>
        default:
            return <></>
    }
}

export default GridCard
