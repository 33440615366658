import { createSlice } from "@reduxjs/toolkit";
import { lookupApi } from "../services/lookupApi";
import { receivedSportsTypeDTOs } from "../DTO/registerDTO";

const initialState = {
  privacy: null,
  terms_condition: null,
  contact_us: null,
};

export const staticContentSlice = createSlice({
  name: "staticContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        lookupApi.endpoints.getPrivacy.matchFulfilled,
        (state, { payload }) => {
          state.privacy = payload.data;
        }
      )
      .addMatcher(lookupApi.endpoints.getPrivacy.matchRejected, (state) => {
        state.privacy = null;
      })
      .addMatcher(
        lookupApi.endpoints.getTermsCondition.matchFulfilled,
        (state, { payload }) => {
          state.terms_condition = payload.data;
        }
      )
      .addMatcher(
        lookupApi.endpoints.getTermsCondition.matchRejected,
        (state) => {
          state.terms_condition = null;
        }
      )
      .addMatcher(
        lookupApi.endpoints.getContactUs.matchFulfilled,
        (state, { payload }) => {
          state.contact_us = payload.data;
        }
      )
      .addMatcher(lookupApi.endpoints.getContactUs.matchRejected, (state) => {
        state.contact_us = null;
      });
  },
});

export const {} = staticContentSlice.actions;

export default staticContentSlice.reducer;
