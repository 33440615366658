import React, { useMemo } from 'react';
import { SubTitle } from '../../../Components/CustomComponents';
import { POST_TYPE_ENUM } from '../../../Constant/constants';
import { PlaySign, PremiumSign, TemplateSign } from '../../CustomComponents';

const GridCard = ({ data = {} }) => {
    const isPremium = useMemo(() => !!data?.is_paid, []);
    const isMine = useMemo(() => !!data?.is_mine, []);

    const handleViewPage = () => {
    }

    switch (data?.type) {
        case POST_TYPE_ENUM.IMAGE:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={handleViewPage} role="button">
                    <img src={data.image_url} alt="" className={`image ${(!isMine && isPremium) ? `premiumImage` : ''}    `} />
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                </div >
                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.caption}
                />
            </>
        case POST_TYPE_ENUM.VIDEO:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={handleViewPage} role="button">
                    <img src={data.thumbnail_url} alt="" className={`image`} />
                    <PlaySign />
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                </div>

                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.caption}
                />
            </>
        case POST_TYPE_ENUM.WORKOUT:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={() => handleViewPage({ isWorkout: true })} role="button">
                    <img src={data.image_url} alt="" className={`image `} />
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                </div>

                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.title}
                />
            </>
        case POST_TYPE_ENUM.TEMPLATE:
            return <>
                <div className='imageContainer focus-in-expand-slow' onClick={() => handleViewPage({ isTemplate: true })} role="button">
                    <div className='templateBox'></div>
                    {
                        isPremium ?
                            <PremiumSign />
                            : ''
                    }
                    <TemplateSign text={'Template 1'} />
                </div>
                <SubTitle
                    classes='fs-5 fw-normal mt-2'
                    text={data.name}
                />
            </>
        default:
            return <></>
    }
}

export default GridCard
