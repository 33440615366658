import React, { useState } from "react";
import ModalContainer from "../ModalContainer";
import { FaCircleXmark } from "react-icons/fa6";
import { STRING } from "../../Constant/StringConstant";
import { Button, Checkbox, Input } from "../CustomComponents";
import { InputGroup } from "react-bootstrap";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { message, Upload } from "antd";
import _ from "lodash";
import Toast from "../../Hooks/useToast";
import { IMAGES } from "../../Constant/ImageConstant";
import MemberSelectionCard from "./MemberSelectionCard";

const { Dragger } = Upload;

const EditGroupModal = ({ handleModalClose }) => {
  const [modalClass, setModalClass] = useState(
    "editGroupModalContainer scale-in-hor-center"
  );

  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleSelect = (id) => {
    setSelectedMembers((prevSelectedMembers) => {
      if (_.includes(prevSelectedMembers, id)) {
        return _.without(prevSelectedMembers, id);
      } else {
        return _.concat(prevSelectedMembers, id);
      }
    });
  }

  const members = [
    {
      id: 1,
      userName: "John Doe",
      userTag: "@johndoe",
      avatarImage: IMAGES.PROFILE_IMAGE,
    },
    {
      id: 2,
      userName: "Jane Smith",
      userTag: "@janesmith",
      avatarImage: IMAGES.POST_IMAGE_2,
    },
    {
      id: 3,
      userName: "Alice Johnson",
      userTag: "@alicej",
      avatarImage: IMAGES.POST_IMAGE,
    },
    {
      id: 4,
      userName: "John Doe",
      userTag: "@johndoe",
      avatarImage: IMAGES.PROFILE_IMAGE,
    },
    {
      id: 5,
      userName: "Jane Smith",
      userTag: "@janesmith",
      avatarImage: IMAGES.POST_IMAGE_2,
    },
    {
      id: 6,
      userName: "Alice Johnson",
      userTag: "@alicej",
      avatarImage: IMAGES.POST_IMAGE,
    },
  ];

  const [mediaImage, setMediaImage] = useState(IMAGES.POST_IMAGE_2);

  const handleModalCloseWithAnimation = () => {
    setModalClass("editGroupModalContainer scale-out-horizontal");
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  // MARK: UPLOAD IMAGE

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpgOrPng) {
      Toast("You can only upload JPG/PNG file!", "error", false);
    } else if (!isLt2M) {
      Toast("Image must smaller than 2MB!", "error", false);
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // upload files
  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: "image/jpeg,image/png,image/png",
    value: !_.isObject(mediaImage)
      ? mediaImage
      : URL.createObjectURL(mediaImage),
    beforeUpload: beforeUpload, // assuming beforeUpload is a function defined elsewhere
    // onChange: handleFileChange, // assuming handleFileChange is a function defined elsewhere
    customRequest: dummyRequest, // assuming dummyRequest is a function defined elsewhere
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        setMediaImage(info.file.originFileObj);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      setMediaImage("");
    },
  };

  return (
    <div>
      <ModalContainer handleClose={handleModalCloseWithAnimation}>
        <div className={modalClass}>
          <div className="d-flex align-items-center w-100 justify-content-between">
            <p className="fs-4 fw-medium m-0">{STRING.EditTeam}</p>

            <div className={"closeBtn"}>
              <FaCircleXmark
                fontSize={40}
                color={"#F68B28"}
                onClick={handleModalCloseWithAnimation}
              />
            </div>
          </div>

          <p className="m-0 me-auto fs-8 text-granite ">
            {STRING.CreateTeamMsg}
          </p>

          <br></br>

          {
            // MARK: DRAGGER
          }
          <Dragger Dragger {...props} className="w-100">
            {!_.isEmpty(mediaImage) ? (
              <img
                src={
                  !_.isObject(mediaImage)
                    ? mediaImage
                    : URL.createObjectURL(mediaImage)
                }
                alt="avatar"
                style={{ width: "100%", height: "100px" }}
                className="img-fluid"
              />
            ) : (
              <div>
                <p className="ant-upload-drag-icon">
                  <img src={IMAGES.UPLOAD_ICON} alt="" />
                </p>
                <p className="ant-upload-text">
                  Drop your image here, or <span>browse</span>
                </p>
                <p className="ant-upload-hint">
                  {" "}
                  Supports: PNG, JPG, JPEG, WEBP{" "}
                </p>
              </div>
            )}
          </Dragger>

          <br></br>

          <InputGroup>
            <Input
              placeholder={STRING.TeamName}
              type={INPUT_TYPES.TEXT}
            // value={value}
            // onChange={onChange}
            // onBlur={onBlur}
            />
          </InputGroup>

          <Button
            classes="scale-in-center-slow fs-6 mt-2 rounded-4"
            text={STRING.SaveButton}
            type={INPUT_TYPES.SUBMIT}
          // onClick={handleSubmit}
          />

          <hr></hr>

          <div className="d-flex flex-row justify-content-between align-content-center w-100 my-2">
            <p className="rounded-2" onClick={() => { }}> {STRING.SelectMembersToRemove} </p>
            <p className="counter"> {STRING.RemoveBtnTxt} </p>
          </div>

          {members.map((member, index) => (
            <div className="w-100">
              {index > 0 ? <hr></hr> : ""}

              <MemberSelectionCard
                key={index}
                isSelected={_.includes(selectedMembers, member.id)}
                onSelect={() => handleSelect(member.id)}
                data={member}
              />
            </div>
          ))}

        </div>
      </ModalContainer>
    </div>
  );
};

export default EditGroupModal;
