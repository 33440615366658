import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';

/**Import Custom Component */
import Navbar from "./Navbar";

import { useDispatch, useSelector } from "react-redux";
// import FilterModal from "../Modals/FilterModal";
// Icons
import { FaArrowRight } from "react-icons/fa6";
import { IoAddCircle } from "react-icons/io5";
import { STRING } from "../../Constant/StringConstant";
// import { unsetUserInfo } from "../../Data/Features/userSlice";
import Toast from "../../Hooks/useToast";
import { removeToken } from "../../Data/services/localStorageService";
import CommunitySvgIcon from "./SVG_Icons/CommunitySvgIcon";
import DashboardSvgIcon from "./SVG_Icons/DashboardSvgIcon";
import PaymentSvgIcon from "./SVG_Icons/PaymentSvgIcon";
import PrivacySvgIcon from "./SVG_Icons/PrivacySvgIcon";
import SettingSvgIcon from "./SVG_Icons/SettingSvgIcon";
import TeamSvgIcon from "./SVG_Icons/TeamSvgIcon";

import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { MdOutlineFeedback } from "react-icons/md";
import { RiProgress6Line } from "react-icons/ri";
import { TbBadges } from "react-icons/tb";
import { TfiEmail } from "react-icons/tfi";
import GlobalStyles from "../../_global.scss";
import { IMAGES } from "../../Constant/ImageConstant";
import AddForumModal from "../Modals/AddForumModal";
import AddModal from "../Modals/AddModal";
import AddPostModal from "../Modals/AddPostModal";
import AddWorkoutModal from "../Modals/AddWorkoutModal";
import AddWorkoutTemplateModal from "../Modals/AddWorkoutTemplateModal";
import LogTrainingModal from "../Modals/LogTrainingModal";
import MenuSvgIcon from "./SVG_Icons/MenuSvgIcon";
import { logout } from "../../Data/Features/authSlice";

export default function ProtectedLayout({
  title,
  isNavBar = false,
  isSearchBar,
  isSettingButton,
  selectedRoute,
  selectedSubItems,
  children,
}) {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);
  const [notificationModal, setNotificationModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [addPostModal, setAddPostModal] = useState(false);
  const [addWorkoutModal, setAddWorkoutModal] = useState(false);
  const [addLogTrainingModal, setAddLogTrainingModal] = useState(false);
  const [addForumModal, setAddForumModal] = useState(false);
  const [addWorkoutTemplateModal, setAddWorkoutTemplateModal] = useState(false);

  const [showTemplate, setShowTemplate] = useState(false);
  const [showPremium, setShowPremium] = useState(false);

  // this is for showTemplate modal on window.event showTemmplate
  useEffect(() => {
    const handleShowTemplate = () => {
      const storedData = localStorage.getItem("showTemplate");
      if (storedData === "true") {
        console.log(storedData);
        setShowTemplate(true);
      } else {
        console.log(storedData);
        setShowTemplate(false);
      }
    };

    window.addEventListener("showTemplate", handleShowTemplate);

    return () => {
      window.removeEventListener("showTemplate", handleShowTemplate);
    };
  }, []);

  // this is for showPurchaseModal modal on window.event showAfterPurchasedModal
  useEffect(() => {
    const handleShowTemplate = () => {
      const storedData = localStorage.getItem("showAfterPurchasedModal");
      if (storedData === "true") {
        console.log(storedData);
        setShowPremium(true);
      } else {
        console.log(storedData);
        setShowPremium(false);
      }
    };

    window.addEventListener("showAfterPurchasedModal", handleShowTemplate);

    return () => {
      window.removeEventListener("showAfterPurchasedModal", handleShowTemplate);
    };
  }, []);

  const { primaryColor } = GlobalStyles;

  const dispatch = useDispatch();

  const handleResize = () => {
    if (window.innerWidth < 991) {
      handleMenuClose();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // todo: menu
  const items = [
    {
      label: STRING.Dashboard,
      icon: <DashboardSvgIcon />,
    },
    {
      label: STRING.Community,
      icon: <CommunitySvgIcon />,
    },
    {
      label: STRING.Menu,
      icon: <MenuSvgIcon />,
      subItems: [
        {
          label: STRING.Progress,
          icon: <RiProgress6Line />,
        },
        {
          label: STRING.Accolades,
          icon: <TbBadges />,
        },
        {
          label: STRING.Feedback,
          icon: <MdOutlineFeedback />,
        },
        { label: STRING.ContactUs, icon: <TfiEmail /> },
      ],
    },
    {
      label: STRING.Team,
      icon: <TeamSvgIcon />,
    },
    {
      label: STRING.Settings,
      icon: <SettingSvgIcon />,
    },
    {
      label: STRING.Payment,
      icon: <PaymentSvgIcon />,
    },
    {
      label: STRING.PrivacyPolicy,
      icon: <PrivacySvgIcon />,
    },
  ];

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  // const handleViewProfile = () => {
  //     navigate(baseRoute(WEB_ROUTES.PROFILE))
  //     if (collapsed && window.innerWidth < 991) {
  //         handleMenuClose();
  //     }
  // }

  const handleAddModalOpen = () => {
    console.log("handleAddModalOpen");
    setAddModal(true);
  };

  const handleAddModalClose = () => {
    console.log("handleAddModalClose");
    setAddModal(false);
  };

  const handleAddPostModalClose = () => {
    console.log("handleAddPostModalClose");
    setAddPostModal(false);
  };

  const handleAddWorkoutModalClose = () => {
    console.log("handleAddWorkoutModalClose");
    setAddWorkoutModal(false);
  };

  const handleLogTrainingModalClose = () => {
    console.log("handleLogTrainingModalClose");
    setAddLogTrainingModal(false);
  };

  const handleAddForumModalClose = () => {
    console.log("handleAddForumModalClose");
    setAddForumModal(false);
  };

  const handleAddWorkoutTemplateModalClose = () => {
    console.log("handleAddWorkoutTemplateModalClose");
    setAddWorkoutTemplateModal(false);
  };

  const handleOpenModal = (number) => {
    // console.log(number)
    switch (number) {
      case 1:
        setTimeout(() => {
          setAddPostModal(true);
        }, 500);
        break;
      case 2:
        setTimeout(() => {
          setAddWorkoutModal(true);
        }, 500);
        break;
      case 3:
        setAddLogTrainingModal(true);
        break;
      case 4:
        setAddForumModal(true);
        break;
      case 5:
        setAddWorkoutTemplateModal(true);
        break;

      default:
        break;
    }
  };

  const handleLogout = () => {
    // dispatch(updateLogin(false));

    try {
      dispatch(logout());
      removeToken();
      window.location.reload();

      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };

  const handleNotificationModalClose = () => {
    setNotificationModal(false);
  };

  const handleNotificationModalOpen = () => {
    // setNotificationModal(true)
  };

  const handleFilterModalClose = () => {
    setFilterModal(false);
  };

  const handleFilterModalOpen = () => {
    setFilterModal(true);
  };
  // console.log(selectedRoute);
  return (
    <div
      className={`${styles.ProtectedLayout} ${
        addModal ||
        addPostModal ||
        addWorkoutModal ||
        addForumModal ||
        addWorkoutTemplateModal ||
        addLogTrainingModal ||
        showTemplate ||
        showPremium
          ? styles.blur5
          : ""
      }`}
    >
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${
          collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
        }`}
        id="sider"
      >
        <div
          className={`p-4 ${styles.logo} ${
            !collapsed ? styles.notCollapsed : ""
          }`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}
        >
          {
            <img
              alt="User Profile"
              src={collapsed ? IMAGES.LOGO : IMAGES.FAVICON}
              className={
                collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
              }
            />
          }
        </div>

        <hr></hr>

        <button
          className={`${styles.profileButton} ${
            !collapsed ? styles.notCollapsed : ""
          } w-75 mx-auto scale-up-center`}
          onClick={handleAddModalOpen}
        >
          <IoAddCircle size={42} className={collapsed ? "m-1" : ""} />

          {collapsed ? STRING.Add : ""}
        </button>
        <br></br>
        <br></br>
        {items.map(({ icon, label, subItems }, index) => (
          <div key={index}>
            <Link
              key={index}
              to={`${process.env.REACT_APP_DOMAIN_DIR}/${label
                .toLowerCase()
                .replace(/[^A-Z0-9]/gi, "-")}`}
              onClick={
                collapsed && window.innerWidth < 991 && !subItems
                  ? handleMenuClose
                  : () => handleSubmenuToggle(index)
              }
              className={`${styles.item} ${
                index === selectedRoute ? styles.active : ""
              } slide-in-left-fast`}
              style={{ animationDelay: `${index * 0.15}s` }}
            >
              {React.cloneElement(icon, {
                color: index === selectedRoute ? primaryColor : "white",
              })}
              {collapsed ? (
                <p
                  style={{
                    color: index === selectedRoute ? primaryColor : "white",
                    transition: "opacity 5s ease",
                  }}
                >
                  {label}
                </p>
              ) : (
                ""
              )}
              {subItems != null ? (
                openSubmenu === index ? (
                  <FaChevronDown className="ms-2" />
                ) : (
                  <FaChevronRight className="ms-2" />
                )
              ) : (
                ""
              )}
            </Link>
            {label === STRING.Menu && openSubmenu === index && (
              <div
                className={`${styles.subMenu}  ${
                  collapsed ? `${styles.collapsed}` : `${styles.notCollapsed}`
                } `}
              >
                {subItems.map((subItem, subIndex) => (
                  <Link
                    to={`${process.env.REACT_APP_DOMAIN_DIR}/${subItem.label
                      .toLowerCase()
                      .replace(/[^A-Z0-9]/gi, "-")}`}
                    key={subIndex}
                    className={`${styles.subItem} ${
                      subIndex === selectedSubItems ? styles.active : ""
                    } ${
                      collapsed ? `` : `${styles.collapsed}`
                    }  slide-in-left-fast`}
                    style={{ animationDelay: `${subIndex * 0.1}s` }}
                    onClick={
                      collapsed && window.innerWidth < 991
                        ? handleMenuClose
                        : ""
                    }
                  >
                    <span
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        marginRight: "6px",
                      }}
                    >
                      {" "}
                      {subItem.icon}{" "}
                    </span>

                    {collapsed ? subItem.label : null}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
        <div
          className="d-flex justify-content-around align-items-center rounded-3 p-2 bg-white text-danger mx-auto fw-bold w-50 my-4"
          onClick={handleLogout}
          style={{ cursor: "pointer" }}
        >
          {collapsed ? <span>Logout</span> : ""}
          <span style={{ fontSize: "16px", display: "flex" }}>
            {" "}
            <FaArrowRight />{" "}
          </span>
        </div>
      </div>
      <div className={styles.routeContent}>
        {isNavBar ? (
          <Navbar
            title={title}
            isSearchBar={isSearchBar}
            isSettingButton={isSettingButton}
            handleToggle={handleToggle}
            handleNotificationModalOpen={handleNotificationModalOpen}
            handleFilterModalOpen={handleFilterModalOpen}
          />
        ) : (
          ""
        )}
        <div className={styles.content}>
          {
            // notificationModal ?
            //     <NotificationModal handleNotificationModalClose={handleNotificationModalClose} />
            //     :
            //     ''
          }

          {
            // filterModal ?
            //     <FilterModal handleFilterModalClose={handleFilterModalClose} />
            //     :
            //     ''
          }

          {addModal ? (
            <AddModal
              handleAddModalClose={handleAddModalClose}
              handleOpenModal={handleOpenModal}
            />
          ) : (
            ""
          )}

          {addPostModal ? (
            <AddPostModal handleModalClose={handleAddPostModalClose} />
          ) : (
            ""
          )}

          {addWorkoutModal ? (
            <AddWorkoutModal handleModalClose={handleAddWorkoutModalClose} />
          ) : (
            ""
          )}

          {addLogTrainingModal ? (
            <LogTrainingModal handleModalClose={handleLogTrainingModalClose} />
          ) : (
            ""
          )}

          {addForumModal ? (
            <AddForumModal handleModalClose={handleAddForumModalClose} />
          ) : (
            ""
          )}

          {addWorkoutTemplateModal ? (
            <AddWorkoutTemplateModal
              handleModalClose={handleAddWorkoutTemplateModalClose}
            />
          ) : (
            ""
          )}

          {children}
        </div>
      </div>
    </div>
  );
}
