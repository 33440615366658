import React from "react";
import { STRING } from "../../Constant/StringConstant";
import {
  Button,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import { INPUT_TYPES } from "../../Constant/InputTypesAndPatternsConstant";
import { Col, Row } from "react-bootstrap";

const Subscription = () => {
  return (
    <div className="Subscription focus-in-expand">
      <SubTitle text={STRING.SubscriptionFeature} />
      <Description text={STRING.SubscriptionDescription} classes="fw-light" />

      <div className="subscription-row">
        <Row>
          <Col sm={12} md={6}>
            <SubTitle text={STRING.SubscriptionDetails} classes="fs-5 " />
            <br></br>
            <br></br>
            <br></br>
            <div className="w-100 purchase-container">
              <Button
                classes="px-4 fs-5 fw-bold scale-in-center-slow w-75 purchase-btn"
                text={STRING.Purchase}
                type={INPUT_TYPES.SUBMIT}
              />
              <Description
                text={STRING.AboutBW_TPP}
                classes="fw-light w-75 text-center"
              />
            </div>
          </Col>
          <Col sm={12} md={6}>
            <center>
              <div className="subscription-board">
                <div className="one-time">
                  <p>{STRING.OneTime}</p>
                </div>
                <div className="center-box">
                  <p className="month"> {STRING.Month}</p>
                  <p className="amount"> {STRING.Amount}</p>
                </div>
              </div>

              <Button
                classes="px-4 fs-5 fw-bold scale-in-center-slow  trial-btn"
                text={STRING.SevenDaysTrial}
                type={INPUT_TYPES.SUBMIT}
              />
            </center>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Subscription;
