import React from "react";

const ExerciseCard = ({ exerciseNumber, heading, text }) => {
  return (
    <div className="exerciseSection">
      <div className="exerciseNumber px-3">
        <span> {exerciseNumber} </span>
      </div>
      <h5>{heading}</h5>
      <p className="m-0 fs-6 text-granite">
        {text}
      </p>
    </div>
  );
};

export default ExerciseCard;
