// Need to use the React-specific entry point to import createApi
import { END_POINTS } from "../../Constant/EndPointsContants";
import { baseApi } from "./baseApi";

// using CSRF token from Local Storage

// Define a service using a base URL and expected endpoints
export const trainingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getTrainingLog: builder.query({
            query: ({ type, date }) => {
                const url = END_POINTS.TRAINING.LOGS(type, date)
                return {
                    url: url,
                    method: "GET",
                };
            },
            refetchOnFocus: true,
            providesTags: (_, __, { type, date }) => [`Logs/Type/${type}/Date/${date}`],
        }),
        getActiveLog: builder.query({
            query: () => {
                const url = END_POINTS.TRAINING.GET_ACTIVE_LOG
                return {
                    url: url,
                    method: "GET",
                };
            },
            refetchOnFocus: true,
            providesTags: [`Logs/Active`]
        }),
        startOrStopLog: builder.mutation({
            query: (data) => {
                const url = END_POINTS.TRAINING.UPDATE_LOG
                return {
                    url: url,
                    method: "PUT",
                    body: data
                };
            },
        }),
    })
})

export const { useGetTrainingLogQuery, useGetActiveLogQuery, useStartOrStopLogMutation } = trainingApi