import React from 'react';

const FreeTag = ({ text }) => {
    return (
        <div className='freeTag scale-in-center'>
            <span>{text}</span>
        </div>
    )
}

export default FreeTag
